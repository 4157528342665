import {
  animation, trigger, animateChild, group,
  transition, animate, style, query, state
} from '@angular/animations';
export const textZoomIn = [
  trigger('EnterLeave', [
    state('flyIn', style({ transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('0.5s 300ms ease-in')
    ]),
    transition(':leave', [
      animate('0.3s ease-out', style({ transform: 'translateY(-100%)' }))
    ])
  ])
]
