import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDesktopComponent } from './home-desktop/home-desktop.component';
import { HomeMobileComponent } from './home-mobile/home-mobile.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AnimateOnScrollModule} from "ng2-animate-on-scroll";
import { OurServicesComponent } from './our-services/our-services.component';
import { FooterComponent } from './footer/footer.component';
import {DesktopGuard, MobileGuard} from "./breakpoint-guard";
import { AgmCoreModule } from '@agm/core';
@NgModule({
  declarations: [
    AppComponent,
    HomeDesktopComponent,
    HomeMobileComponent,
    OurServicesComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgzZLKci027eBRixTxgy2Vb571nRJ4Vfw'
    }),
    AnimateOnScrollModule.forRoot(),
    AppRoutingModule
  ],
  providers: [DesktopGuard, MobileGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
