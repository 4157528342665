<div id="SITE_CONTAINER">
  <div id="main_MF">
    <div id="site-root">
      <div id="masterPage" class="mesh-layout">
        <div id="SITE_HEADER" class="_11CDm" tabindex="-1">
          <div class="_1nb6T">
            <div class="mTQQp"></div>
          </div>
          <div class="_2WCQx">
            <div class="_3l5s9">
              <div class="_1VpWy"></div>
            </div>
            <div class="_3sXYI">
              <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                <div data-mesh-id="SITE_HEADERinlineContent-gridContainer" data-testid="mesh-container-content">
                  <div id="comp-kjhmuu8i" class="_2TxBB _3TiYw" title=""><a data-testid="linkElement"
                                                                            routerLink=""
                                                                            class="_2yXCL">
                    <!--<wix-image id="img_comp-kjhmuu8i" class="_1SQyl DFk41"-->
                    <!--data-image-info="{&quot;containerId&quot;:&quot;comp-kjhmuu8i&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1178,&quot;height&quot;:305,&quot;uri&quot;:&quot;23447d_001090e20557417e857e354fcbb920cb~mv2.png&quot;,&quot;name&quot;:&quot;Copy%20of%20Grey%20Box%20Photography%20L&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"-->
                    <!--data-bg-effect-name="" data-is-svg="false" data-is-svg-mask="false" data-image-zoomed=""-->
                    <!--data-has-ssr-src=""-->
                    <!--data-src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_245,h_64,al_c,q_85,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.webp">-->
                    <img class="_1SQyl DFk41"
                         src="./../../assets/KELSEYMANAGEMENT.png"
                         alt="Copy%20of%20Grey%20Box%20Photography%20L"
                         style="width:180px;height:64px;object-fit:cover;object-position:50% 50%">
                    <!--</wix-image>-->
                  </a></div>
                  <div id="comp-j6gqwjnf" class="_13c26 _2cbNh hidden-during-prewarmup" dir="ltr"
                       data-stretch-buttons-to-menu-width="true" data-same-width-buttons="false"
                       data-num-items="4" data-menuborder-y="0" data-menubtn-border="0"
                       data-ribbon-els="0" data-label-pad="0" data-ribbon-extra="0"
                       data-dropalign="center" style="visibility: inherit; overflow-x: visible;"
                       data-dropmode="dropDown">
                    <nav class="zmzdJ" id="comp-j6gqwjnfnavContainer" aria-label="Site">
                      <ul class="_3ryjj" id="comp-j6gqwjnfitemsContainer" style="text-align:center"
                          data-marginallchildren="true">
                        <li id="comp-j6gqwjnf0" data-direction="ltr" data-listposition="left"
                            data-data-id="dataItem-kjhmd1522" data-state="menu false  link" data-index="0"
                            class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                            style="width: 142px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                          <a data-testid="linkElement" data-anchor="dataItem-j6utb6th"
                             href="#comp-j7gex564" target="_self" class="_25Cim"
                             aria-haspopup="false">
                            <div class="_3zfdd">
                              <div class="" style="text-align:left"><p class="_3YCIf"
                                                                       style="text-align: left; line-height: 50px;"
                                                                       id="comp-j6gqwjnf0label">WHAT WE DO</p></div>
                            </div>
                          </a></li>
                        <li id="comp-j6gqwjnf1" data-direction="ltr" data-listposition="center"
                            data-data-id="dataItem-kjjq6ro0" data-state="menu false  link" data-index="1"
                            class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                            style="width: 170px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                          <a data-testid="linkElement" data-anchor="dataItem-kjhuek4c"
                             href="#comp-kjhqm0wa" target="_self" class="_25Cim"
                             aria-haspopup="false">
                            <div class="_3zfdd">
                              <div class="" style="text-align:left"><p class="_3YCIf"
                                                                       style="text-align: left; line-height: 50px;"
                                                                       id="comp-j6gqwjnf1label">WHY CHOOSE US</p></div>
                            </div>
                          </a></li>
                        <li id="comp-j6gqwjnf2" data-direction="ltr" data-listposition="center"
                            data-data-id="dataItem-kjjqqocq" data-state="menu false  link" data-index="2"
                            class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                            style="width: 148px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                          <a data-testid="linkElement" routerLink="/our-services"
                             target="_self" class="_25Cim" aria-haspopup="false">
                            <div class="_3zfdd">
                              <div class="" style="text-align:left"><p class="_3YCIf"
                                                                       style="text-align: left; line-height: 50px;"
                                                                       id="comp-j6gqwjnf2label">OUR SERVICES</p></div>
                            </div>
                          </a></li>
                        <li id="comp-j6gqwjnf3" data-direction="ltr" data-listposition="right"
                            data-data-id="dataItem-kjmkaice" data-state="menu false  link" data-index="3"
                            class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                            style="width: 139px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                          <a data-testid="linkElement" data-anchor="dataItem-kjhufft4"
                             href="#comp-j7gi7kz8" target="_self" class="_25Cim"
                             aria-haspopup="false">
                            <div class="_3zfdd">
                              <div class="" style="text-align:left"><p class="_3YCIf"
                                                                       style="text-align: left; line-height: 50px;"
                                                                       id="comp-j6gqwjnf3label">CONTACT US</p></div>
                            </div>
                          </a></li>
                        <li id="comp-j6gqwjnf__more__" data-direction="ltr" data-listposition="right"
                            data-state="menu false  header" data-index="__more__" data-dropdown="false"
                            class="_2LjR0 _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="true"
                            style="height: 0px; overflow: hidden; position: absolute; visibility: hidden;">
                          <div data-testid="linkElement" class="_25Cim" tabindex="0" aria-haspopup="false">
                            <div class="_3zfdd">
                              <div class="" style="text-align:left"><p class="_3YCIf" style="text-align:left"
                                                                       id="comp-j6gqwjnf__more__label" tabindex="-1">
                                More</p></div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="GQcif" id="comp-j6gqwjnfdropWrapper" data-dropalign="center"
                           data-dropdown-shown="false">
                        <ul class="_3u_t0" id="comp-j6gqwjnfmoreContainer"></ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header id="SITE_HEADER_WRAPPER">
          <div id="pinnedMiddleRight">
            <div id="comp-j70tm1gq-pinned-layer" class="_1w0tU">
              <div id="comp-j70tm1gq" class="">
                <a href="#SCROLL_TO_TOP" class="_3lfWv">
                  <div data-testid="svgRoot-comp-j70tm1gq" class="_36XXs _3Qjwl">
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="6.443 47.497 187.114 105.008"
                         xmlns="http://www.w3.org/2000/svg" viewBox="6.443 47.497 187.114 105.008" role="img"
                         aria-labelledby="svgcid--qsip5ehmzy0q"><title id="svgcid--qsip5ehmzy0q">Top of Page</title>
                      <g>
                        <path
                          d="M174.476 51.261l-74.704 74.193L25.578 50.75c-4.287-4.316-11.566-4.341-15.882-.054s-4.341 11.565-.055 15.882l82.107 82.673c4.287 4.316 11.566 4.341 15.882.055l82.673-82.107c4.316-4.287 4.341-11.566.055-15.882s-11.235-4.342-15.882-.056z"></path>
                      </g>
                    </svg>
                  </div>
                </a></div>
            </div>
          </div>
        </header>
        <main id="PAGES_CONTAINER" tabindex="-1">
          <div id="SITE_PAGES">
            <div class="_3a3qX">
              <div id="c1dmp" class="_2e6ps">
                <div class="_2HjkL"></div>
                <div class="_2S9ms">
                  <div id="Containerc1dmp" class="_3Mgpu">
                    <div data-mesh-id="Containerc1dmpinlineContent" data-testid="inline-content" class="">
                      <div data-mesh-id="Containerc1dmpinlineContent-gridContainer"
                           data-testid="mesh-container-content">
                        <section id="comp-j6gmckgo">
                          <div id="bgLayers_comp-j6gmckgo" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j6gmckgo" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-j6gmckir" class="_1HpZ_">
                              <div id="bgLayers_comp-j6gmckir" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j6gmckir" class="_33YMf">
                                  <!--<wix-image id="img_comp-j6gmckir" class="_1SQyl FgquG _3r8sA cNGww bgImage"-->
                                  <!--data-image-info="{&quot;containerId&quot;:&quot;comp-j6gmckir&quot;,&quot;alignType&quot;:&quot;center&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:3000,&quot;height&quot;:2002,&quot;uri&quot;:&quot;23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"-->
                                  <!--data-has-bg-scroll-effect="" data-bg-effect-name="" data-is-svg="false"-->
                                  <!--data-is-svg-mask="false" data-image-zoomed="" data-has-ssr-src=""-->
                                  <!--data-src="https://static.wixstatic.com/media/23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.jpg/v1/fill/w_1160,h_585,al_c,q_85,usm_0.66_1.00_0.01/23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.webp">-->
                                  <img
                                    class="_1SQyl FgquG _3r8sA cNGww bgImage"
                                    src="./../../assets/home-page.jpg"
                                    alt="shutterstock_124544674_edited.jpg"
                                    style="width: 1160px; height: 585px; object-fit: cover; object-position: 50% 50%;">
                                  <!--</wix-image>-->
                                </div>
                              </div>
                              <div data-mesh-id="comp-j6gmckirinlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j6gmckirinlineContent-gridContainer"
                                     data-testid="mesh-container-content"></div>
                              </div>
                            </div>
                            <div id="comp-j6gn42bm" class="_1HpZ_">
                              <div id="bgLayers_comp-j6gn42bm" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j6gn42bm" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j6gn42bminlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j6gn42bminlineContent-gridContainer"
                                     data-testid="mesh-container-content"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section id="comp-j82z235y">
                          <div id="bgLayers_comp-j82z235y" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j82z235y" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-j82z237y" class="_1HpZ_">
                              <div id="bgLayers_comp-j82z237y" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j82z237y" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j82z237yinlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j82z237yinlineContent-gridContainer"
                                     data-testid="mesh-container-content"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div id="comp-kjhmgj48" class="_3e89g">
                          <div data-mesh-id="comp-kjhmgj48inlineContent" data-testid="inline-content" class="">
                            <div data-mesh-id="comp-kjhmgj48inlineContent-gridContainer"
                                 data-testid="mesh-container-content">
                              <form id="comp-kjhmgj4f1" class="Cdk9j">
                                <div data-mesh-id="comp-kjhmgj4f1inlineContent" data-testid="inline-content" class="">
                                  <div data-mesh-id="comp-kjhmgj4f1inlineContent-gridContainer"
                                       data-testid="mesh-container-content">
                                    <div id="comp-kjhmgj55" class="_1Z_nJ" data-testid="richTextElement"><h5
                                      class="font_5" style="font-size:38px"><span style="font-size:38px"><span
                                      style="color:#ffffff"><span
                                      style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">We Offer Complete Property Management Solutions</span></span></span>
                                    </h5>

                                      <h5 class="font_5" style="font-size:17px; line-height:2.3em"><span
                                        style="font-size:17px"><span class="color_11"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Schedule a call with our dedicated team today!</span></span></span>
                                      </h5></div>
                                    <div id="comp-kjhmgj5h" class="_31qkb _1uYgo PEjbJ">
                                      <label for="input_comp-kjhmgj5h" class="_2UrhM">Name</label>
                                      <div class="Lc5OM">
                                        <input type="text" name="name" id="input_comp-kjhmgj5h"
                                               class="A1_tY has-custom-focus" value=""
                                               placeholder="E.g., Kat" required="" maxlength="100">
                                      </div>
                                    </div>
                                    <!--<div id="comp-kjhmgj5s" class="_31qkb _1uYgo _2_9-v">-->
                                    <!--<label for="input_comp-kjhmgj5s" class="_2UrhM">Last Name</label>-->
                                    <!--<div class="Lc5OM">-->
                                    <!--<input type="text" name="last-name" id="input_comp-kjhmgj5s"-->
                                    <!--class="A1_tY has-custom-focus" value=""-->
                                    <!--placeholder="E.g., Jones" maxlength="100">-->
                                    <!--</div>-->
                                    <!--</div>-->
                                    <div id="comp-kjhmgj5v" class="_31qkb _1uYgo PEjbJ">
                                      <label for="input_comp-kjhmgj5v" class="_2UrhM">Email
                                      </label>
                                      <div class="Lc5OM">
                                        <input type="email" name="email" id="input_comp-kjhmgj5v"
                                               class="A1_tY has-custom-focus" value=""
                                               placeholder="E.g., mail@example.com" required=""
                                               pattern="^.+@.+\.[a-zA-Z]{2,63}$" maxlength="250"></div>
                                    </div>
                                    <div id="comp-kjhmgj5x3" class="_31qkb _1uYgo PEjbJ _2_9-v">
                                      <label for="input_comp-kjhmgj5x3" class="_2UrhM">Phone</label>
                                      <div class="Lc5OM">
                                        <input type="tel" name="phone" id="input_comp-kjhmgj5x3"
                                               class="A1_tY has-custom-focus"
                                               value="" placeholder="e.g., 123 456 7890" required="" maxlength="50">
                                      </div>
                                    </div>
                                    <div id="comp-kjhmgj601" class="_42POZ _2ugKu">
                                      <label for="textarea_comp-kjhmgj601"
                                             class="_2SAi8">Message</label><textarea
                                      id="textarea_comp-kjhmgj601" class="_1qIig has-custom-focus"
                                      placeholder="Type your message here..."></textarea></div>
                                    <div id="comp-kjhmgj682" aria-disabled="false" class="_22oPj">
                                      <button data-testid="buttonElement" class="_1_kc0 getfreeevaluation"><span
                                        class="_3fUtn">Submit</span></button>
                                    </div>
                                  </div>
                                </div>
                                <div class="FHREF"><label>Please leave this field empty.<input type="text"
                                                                                               name="message"
                                                                                               autocomplete="nada"
                                                                                               tabindex="-1"
                                                                                               value=""></label></div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div id="comp-j6utb6sn" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                             aria-label="Our Services">&nbsp;
                        </div>
                        <section id="comp-j7gex564">
                          <div id="bgLayers_comp-j7gex564" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j7gex564" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-j7gex57b" class="_1HpZ_">
                              <div id="bgLayers_comp-j7gex57b" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j7gex57b" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j7gex57binlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j7gex57binlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div id="comp-j7gdpzlb" class="_1Z_nJ" data-testid="richTextElement"><h2
                                    class="font_2" style="font-size:55px"><span style="color:#ffffff"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">What We Do</span></span>
                                  </h2></div>
                                  <div id="comp-k41aqqhb" animateOnScroll
                                       animationName="animate__animated animate__fadeIn animated"
                                       class="hide-on-init _1Z_nJ animating-screenIn-exit">
                                    <p class="font_8"
                                       style="line-height:1.7em;font-size:16px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      class="color_11"><span
                                      style="letter-spacing:0.04em">
                                      The Kelsey Management Team has had a reputation for delivering best in class service. With operations based in Florida, Kelsey Management creates customized structures and strategies for each client’s unique and diversified needs. We strive to provide creative solutions to ensure we create a reliable partnership to suit your business. We offer full service property management, with extensive experience in homeowner associations, master planned communities, self-managed properties, and low-rise and high-rise condominiums.&nbsp;
                                    </span></span></span>
                                    </p></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section id="comp-j82zcvl7">
                          <div id="bgLayers_comp-j82zcvl7" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j82zcvl7" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-j82zcvmc" class="_1HpZ_">
                              <div id="bgLayers_comp-j82zcvmc" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j82zcvmc" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j82zcvmcinlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j82zcvmcinlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div id="comp-j7geq3in" class="_1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7geq3ininlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7geq3ininlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7geey4n" class="_1lRal">
                                          <div class="_2iB5S"></div>
                                          <div data-mesh-id="comp-j7geey4ninlineContent" data-testid="inline-content"
                                               class="">
                                            <div animateOnScroll
                                                 animationName="animate__animated animate__fadeIn animated"
                                                 class="hide-on-init"
                                                 data-mesh-id="comp-j7geey4ninlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-j7geey4s" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><h3
                                                class="font_5" style="font-size:28px"><span style="color:#26436B"><span
                                                style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Homeowners&nbsp;</span></span></span><span
                                                style="color:#26436B"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Association</span></span></span>
                                              </h3></div>
                                              <div id="comp-j7geeufj" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><p
                                                class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.04em">
                                                Kelsey Management provides a complete scope of services to ensure seamless financial, administrative, and operational requirements for your community.
                                              </span></span>
                                              </p>

                                                <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="letter-spacing:0.04em"><span
                                                  class="wixGuard">&#8203;</span></span></span></p></div>
                                              <div id="comp-j7geey4o" aria-disabled="false"
                                                   class="_22oPj animating-screenIn-exit" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><a
                                                data-testid="linkElement"
                                                href="https://www.tgpropertymanagementgroup.com/our-services"
                                                target="_self" class="_1_kc0"><span
                                                class="_3fUtn">+ Learn More</span></a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="comp-j7getfql" class="_1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7getfqlinlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7getfqlinlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7getfqn" class="_1lRal">
                                          <div class="_2iB5S"></div>
                                          <div data-mesh-id="comp-j7getfqninlineContent" data-testid="inline-content"
                                               class="">
                                            <div animateOnScroll
                                                 animationName="animate__animated animate__fadeIn animated"
                                                 class="hide-on-init"
                                                 data-mesh-id="comp-j7getfqninlineContent-gridContainer"
                                                 data-testid="mesh-container-content" style="height: 402px!important;">
                                              <div id="comp-j7getfqr1" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><h3
                                                class="font_5" style="font-size:28px"><span style="color:#26436B"><span
                                                style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Low and Mid-Rise Condominiums</span></span></span>
                                              </h3></div>
                                              <div id="comp-j7getfqq" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><p
                                                class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.04em">
                                                Maintenance, rent collection, tenant services, and screenings - we  are capable of navigating the high-level and detail-specific needs of your property.
                                              </span></span>
                                              </p></div>
                                              <div id="comp-j7getfqo" aria-disabled="false"
                                                   class="_22oPj animating-screenIn-exit" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><a
                                                data-testid="linkElement"
                                                href="https://www.tgpropertymanagementgroup.com/our-services"
                                                target="_self" class="_1_kc0"><span
                                                class="_3fUtn">+ Learn More</span></a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="comp-j7getkmq" class=" _1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7getkmqinlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7getkmqinlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7getkmr" class="_1lRal">
                                          <div class="_2iB5S"></div>
                                          <div data-mesh-id="comp-j7getkmrinlineContent" data-testid="inline-content"
                                               class="">
                                            <div animateOnScroll
                                                 animationName="animate__animated animate__fadeIn animated"
                                                 class="hide-on-init"
                                                 data-mesh-id="comp-j7getkmrinlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-j7getkmx" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><h3
                                                class="font_5" style="font-size:28px"><span style="color:#26436B"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="font-weight:bold">High-Rise  </span></span></span><span
                                                style="color:#26436B"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="font-weight:bold">Condominiums</span></span></span></h3></div>
                                              <div id="comp-j7getkmu" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><p
                                                class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.04em">
                                                Our Team is well-equipped to handle every need of your building’s residents. We are skilled at navigating and meeting the demands of luxury communities across Florida.
                                              </span></span>
                                              </p>

                                                <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="letter-spacing:0.04em"><span
                                                  class="wixGuard">&#8203;</span></span></span></p></div>
                                              <div id="comp-j7getkms" aria-disabled="false"
                                                   class="_22oPj animating-screenIn-exit" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><a
                                                data-testid="linkElement"
                                                href="https://www.tgpropertymanagementgroup.com/our-services"
                                                target="_self" class="_1_kc0"><span
                                                class="_3fUtn">+ Learn More</span></a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="comp-j7gf5quy" class=" _1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7gf5quyinlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7gf5quyinlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7gf5quz" class="_1lRal">
                                          <div class="_2iB5S"></div>
                                          <div data-mesh-id="comp-j7gf5quzinlineContent" data-testid="inline-content"
                                               class="">
                                            <div animateOnScroll
                                                 animationName="animate__animated animate__fadeIn animated"
                                                 class="hide-on-init"
                                                 data-mesh-id="comp-j7gf5quzinlineContent-gridContainer"
                                                 data-testid="mesh-container-content" style="height: 426px!important;">
                                              <div id="comp-j7gf5qv5" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><h3
                                                class="font_5" style="font-size:28px"><span style="color:#26436B"><span
                                                style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Developer&nbsp;</span></span></span><span
                                                style="color:#26436B"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Services</span></span></span>
                                              </h3></div>
                                              <div id="comp-j7gf5qv3" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><p
                                                class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.04em">With our expertise in full service support and management, we have a strong track record of partnering with developers to make sure their business goals become a reality.&nbsp;</span></span>
                                              </p></div>
                                              <div id="comp-j7gf5qv01" aria-disabled="false"
                                                   class="_22oPj animating-screenIn-exit" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><a
                                                data-testid="linkElement"
                                                href="https://www.tgpropertymanagementgroup.com/our-services"
                                                target="_self" class="_1_kc0"><span
                                                class="_3fUtn">+ Learn More</span></a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="comp-j7gf5qve1" class="_1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7gf5qve1inlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7gf5qve1inlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7gf5qvg" class="_1lRal">
                                          <div class="_2iB5S"></div>
                                          <div data-mesh-id="comp-j7gf5qvginlineContent" data-testid="inline-content"
                                               class="">
                                            <div animateOnScroll
                                                 animationName="animate__animated animate__fadeIn animated"
                                                 class="hide-on-init"
                                                 data-mesh-id="comp-j7gf5qvginlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-j7gf5qvl1" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><h3
                                                class="font_5" style="font-size:28px"><span style="color:#26436B"><span
                                                style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Commercial&nbsp;</span></span></span><span
                                                style="color:#26436B"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Properties</span></span></span>
                                              </h3></div>
                                              <div id="comp-j7gf5qvk" class="_1Z_nJ animating-screenIn-exit"
                                                   data-testid="richTextElement" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><p
                                                class="font_8" style="line-height:1.7em;font-size:16px"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.04em">
                                              With a team committed to comprehensive management, we successfully operate and oversee commercial properties of all sizes to create successful outcomes for your business.
                                              </span></span>
                                              </p></div>
                                              <div id="comp-j7gf5qvh" aria-disabled="false"
                                                   class="_22oPj animating-screenIn-exit" data-angle="0"
                                                   data-angle-style-location="style" style="visibility: inherit;"><a
                                                data-testid="linkElement"
                                                href="https://www.tgpropertymanagementgroup.com/our-services"
                                                target="_self" class="_1_kc0"><span
                                                class="_3fUtn">+ Learn More</span></a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div data-mesh-id="comp-j82zcvmcinlineContent-wedge-3"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section id="comp-kjhqm0wa">
                          <div id="bgLayers_comp-kjhqm0wa" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-kjhqm0wa" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-kjhqm0za" class="_1HpZ_">
                              <div id="bgLayers_comp-kjhqm0za" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-kjhqm0za" class="_33YMf">
                                  <img
                                    src="https://static.wixstatic.com/media/11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg/v1/fill/w_1349,h_566,al_c,q_85,usm_0.66_1.00_0.01/11062b_59e2154cc2964841844a6912615cc6f2~mv2.webp"
                                    alt="Residential Homes"
                                    style="width: 1349px; height: 566px; object-fit: cover; object-position: 50% 50%;">

                                </div>
                              </div>
                              <div data-mesh-id="comp-kjhqm0zainlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-kjhqm0zainlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div id="comp-kjhqnj3b" class="_1Z_nJ" data-testid="richTextElement"><h2
                                    class="font_2" style="font-size:55px"><span style="color:#ffffff"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Why Choose Us</span></span>
                                  </h2></div>
                                  <div id="comp-kjhqrmqa" data-angle="0" data-angle-style-location="style"
                                       class="animating-screenIn-exit" style="visibility: inherit;">
                                    <div role="grid" class="_2Td_x">
                                      <div role="row" animateOnScroll
                                           animationName="animate__animated animate__fadeIn animated"
                                           class="hide-on-init _2VBm0">
                                        <div role="gridcell" class="bDfMI">
                                          <div id="comp-kjhqrmsc__item1" class="_1HpZ_">
                                            <div id="bgLayers_comp-kjhqrmsc__item1" class="_3_wbk">
                                              <div class="P0kib FgquG"></div>
                                              <div id="bgMedia_comp-kjhqrmsc__item1" class="_33YMf"></div>
                                            </div>
                                            <div data-mesh-id="comp-kjhqrmsc__item1inlineContent"
                                                 data-testid="inline-content" class="" style="height: 342px !important;">
                                              <div data-mesh-id="comp-kjhqrmsc__item1inlineContent-gridContainer"
                                                   data-testid="mesh-container-content">
                                                <div id="comp-kjhqrmss__item1" class="_2TxBB" title="">
                                                  <div data-testid="linkElement" class="_2yXCL">
                                                    <img
                                                      src="https://static.wixstatic.com/media/23447d_c66909521f00423085e8fa68a8b6d446~mv2.png/v1/fill/w_82,h_74,al_c,q_85,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo.webp"
                                                      alt="Grey Box Photography Logo.png"
                                                      style="width:82px;height:74px;object-fit:cover;object-position:50% 50%">

                                                  </div>
                                                </div>
                                                <div id="comp-kjhqrmsw1__item1" class="_1Z_nJ"
                                                     data-testid="richTextElement"><h4
                                                  style="font-size:24px; text-align:center"><span
                                                  style="letter-spacing:0.1em"><span style="font-size:24px"><span
                                                  style="color:#000000"><span style="font-weight:bold"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                                  PREPAREDNESS
                                                </span></span></span></span></span>
                                                </h4></div>
                                                <div id="comp-kjhqrmt1__item1" class="_1Z_nJ"
                                                     data-testid="richTextElement"><p class="font_8"
                                                                                      style="font-size:15px; line-height:1.5em; text-align:center">
                                                  <span style="font-size:15px"><span
                                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                    style="color:#545454">
                                                    We utilize our extensive experience to remain strategic and agile in our approach to challenges that arise.
                                                  </span></span></span>
                                                </p></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div role="row" animateOnScroll
                                           animationName="animate__animated animate__fadeIn animated"
                                           class="hide-on-init _2VBm0">
                                        <div role="gridcell" class="bDfMI">
                                          <div id="comp-kjhqrmsc__item-j9sd2so8" class="_1HpZ_">
                                            <div id="bgLayers_comp-kjhqrmsc__item-j9sd2so8" class="_3_wbk">
                                              <div class="P0kib FgquG"></div>
                                              <div id="bgMedia_comp-kjhqrmsc__item-j9sd2so8" class="_33YMf"></div>
                                            </div>
                                            <div data-mesh-id="comp-kjhqrmsc__item-j9sd2so8inlineContent"
                                                 data-testid="inline-content" class="">
                                              <div
                                                data-mesh-id="comp-kjhqrmsc__item-j9sd2so8inlineContent-gridContainer"
                                                data-testid="mesh-container-content">
                                                <div id="comp-kjhqrmss__item-j9sd2so8" class="_2TxBB" title="">
                                                  <div data-testid="linkElement" class="_2yXCL">
                                                    <img
                                                      src="https://static.wixstatic.com/media/23447d_c6e90e2f04c94ec0ac84d99a4643fb36~mv2.png/v1/fill/w_82,h_74,al_c,q_85,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(2).webp"
                                                      alt="Grey Box Photography Logo (2).png"
                                                      style="width:82px;height:74px;object-fit:cover;object-position:50% 50%">

                                                  </div>
                                                </div>
                                                <div id="comp-kjhqrmsw1__item-j9sd2so8" class="_1Z_nJ"
                                                     data-testid="richTextElement"><h4
                                                  style="font-size:24px; text-align:center"><span
                                                  style="font-weight:bold"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="letter-spacing:0.1em"><span style="font-size:24px"><span
                                                  style="color:#000000">
                                                  PRECISION
                                                </span></span></span></span></span>
                                                </h4></div>
                                                <div id="comp-kjhqrmt1__item-j9sd2so8" class="_1Z_nJ"
                                                     data-testid="richTextElement"><p class="font_8"
                                                                                      style="font-size:15px; line-height:1.5em; text-align:center">
                                                  <span style="font-size:15px"><span
                                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                    style="color:#545454">
                                                    Creating management and operational efficiencies is our goal - we want to positively impact your bottom line and ensure there is no waste of your valuable time or resources.
                                                  </span></span></span>
                                                </p></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div role="row" animateOnScroll
                                           animationName="animate__animated animate__fadeIn animated"
                                           class="hide-on-init _2VBm0">
                                        <div role="gridcell" class="bDfMI">
                                          <div id="comp-kjhqrmsc__item-j9sd2rk2" class="_1HpZ_">
                                            <div id="bgLayers_comp-kjhqrmsc__item-j9sd2rk2" class="_3_wbk">
                                              <div class="P0kib FgquG"></div>
                                              <div id="bgMedia_comp-kjhqrmsc__item-j9sd2rk2" class="_33YMf"></div>
                                            </div>
                                            <div data-mesh-id="comp-kjhqrmsc__item-j9sd2rk2inlineContent"
                                                 data-testid="inline-content" class="" style="height: 342px !important;">
                                              <div
                                                data-mesh-id="comp-kjhqrmsc__item-j9sd2rk2inlineContent-gridContainer"
                                                data-testid="mesh-container-content">
                                                <div id="comp-kjhqrmss__item-j9sd2rk2" class="_2TxBB" title="">
                                                  <div data-testid="linkElement" class="_2yXCL">
                                                    <div class="_1ShVK" style="object-position:50% 50%">
                                                      <svg width="82" height="74" viewBox="0 0 82 74"
                                                           ref="svgcomp-kjhqrmss__item-j9sd2rk2"
                                                           key="svgcomp-kjhqrmss__item-j9sd2rk2"
                                                           id="svgcomp-kjhqrmss__item-j9sd2rk2" version="1.1"
                                                           role="img" aria-label="Grey Box Photography Logo (1).png">
                                                        <defs>
                                                          <filter id="mask-comp-kjhqrmss__item-j9sd2rk2-svg-bleach">
                                                            <feColorMatrix type="matrix" values="0 0 0 0 1
				0 0 0 0 1
				0 0 0 0 1
				0 0 0 1 0"></feColorMatrix>
                                                          </filter>
                                                          <mask id="mask-comp-kjhqrmss__item-j9sd2rk2">
                                                            <use
                                                              filter="url(#mask-comp-kjhqrmss__item-j9sd2rk2-svg-bleach)"
                                                              id="mask-comp-kjhqrmss__item-j9sd2rk2-svg-use"
                                                              xlink:href="#mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                              width="100%" height="100%" x="0" y="0"></use>
                                                          </mask>
                                                          <svg id="mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                               preserveAspectRatio="none" data-bbox="20 20 160 160"
                                                               viewBox="20 20 160 160" height="200" width="200"
                                                               xmlns="http://www.w3.org/2000/svg" data-type="shape">
                                                            <g>
                                                              <path d="M180 20v160H20V20h160z"></path>
                                                            </g>
                                                          </svg>
                                                        </defs>
                                                        <image
                                                          xlink:href="https://static.wixstatic.com/media/23447d_79081c765ff5491f9cb99fa52ae645b1~mv2.png/v1/crop/x_57,y_94,w_896,h_811/fill/w_82,h_74,al_c,q_85,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(1).webp"
                                                          key="image" data-type="image" width="82" height="74"
                                                          preserveAspectRatio="xMidYMid slice"
                                                          mask="url(#mask-comp-kjhqrmss__item-j9sd2rk2)"
                                                          data-svg-mask="mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                          style="width: 82px; height: 74px; object-fit: cover;"></image>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div id="comp-kjhqrmsw1__item-j9sd2rk2" class="_1Z_nJ"
                                                     data-testid="richTextElement"><h4
                                                  style="font-size:24px; text-align:center"><span
                                                  style="font-weight:bold"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="letter-spacing:0.1em"><span style="font-size:24px"><span
                                                  style="color:#000000">PERFORMANCE
                                                </span></span></span></span></span>
                                                </h4></div>
                                                <div id="comp-kjhqrmt1__item-j9sd2rk2" class="_1Z_nJ"
                                                     data-testid="richTextElement"><p class="font_8"
                                                                                      style="font-size:15px; line-height:1.5em; text-align:center">
                                                  <span style="font-size:15px"><span
                                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                    style="color:#545454">
                                                    Integrity is at the heart of our operation - trust is paramount and we never lose sight of advocating for your business needs and producing successful results.
                                                  </span>
                                                  </span>
                                                  </span>
                                                </p></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div id="comp-kjhuek2y" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                             aria-label="Why Choose Us?">&nbsp;
                        </div>
                        <section id="comp-j82zhvnq">
                          <div id="bgLayers_comp-j82zhvnq" class="_3_wbk">
                            <div class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j82zhvnq" class="_33YMf"></div>
                          </div>
                          <div class="_3BQmz">
                            <div id="comp-j82zhvp2" class="_1HpZ_">
                              <div id="bgLayers_comp-j82zhvp2" class="_3_wbk">
                                <div class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j82zhvp2" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j82zhvp2inlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j82zhvp2inlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div data-mesh-id="comp-kjmlfwgo-rotated-wrapper">
                                    <div id="comp-kjmlfwgo" class="">
                                      <a data-testid="linkElement" href="#SCROLL_TO_TOP" class="_3lfWv">
                                        <div data-testid="svgRoot-comp-kjmlfwgo" class="_36XXs _3Qjwl">
                                          <svg preserveAspectRatio="none" data-bbox="6.443 47.497 187.114 105.008"
                                               xmlns="http://www.w3.org/2000/svg" viewBox="6.443 47.497 187.114 105.008"
                                               role="img" aria-labelledby="svgcid--eykdsv-6iyd3z"><title
                                            id="svgcid--eykdsv-6iyd3z">Top of Page</title>
                                            <g>
                                              <path
                                                d="M174.476 51.261l-74.704 74.193L25.578 50.75c-4.287-4.316-11.566-4.341-15.882-.054s-4.341 11.565-.055 15.882l82.107 82.673c4.287 4.316 11.566 4.341 15.882.055l82.673-82.107c4.316-4.287 4.341-11.566.055-15.882s-11.235-4.342-15.882-.056z"></path>
                                            </g>
                                          </svg>
                                        </div>
                                      </a></div>
                                  </div>
                                  <div id="comp-j7ghlthx" class="_1lRal">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j7ghlthxinlineContent" data-testid="inline-content"
                                         class="">
                                      <div data-mesh-id="comp-j7ghlthxinlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j7gho0xn" class="_1Z_nJ" data-testid="richTextElement"><h2
                                          class="font_2" style="font-size:55px"><span style="color:#26436B;"><span
                                          style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif;">Partner With Us Today</span></span>
                                        </h2></div>
                                        <div id="comp-j7ghqwti1" class="_1e6g2"></div>
                                        <div id="comp-j7ghqwti" class="_1Z_nJ" data-testid="richTextElement"><p
                                          class="font_8" style="line-height:1.7em;font-size:16px"><span
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                          style="letter-spacing:0.04em">
                                          The Kelsey Management Team understands that all properties, businesses, and circumstances are unique. Our approach is tailored and hands-on to ensure that we are of service to you in maximizing your property’s potential. We pride ourselves on being accessible, exceeding expectations, and ultimately delivering service that generates business growth for our clients.
                                        </span>
                                        </span>
                                          <!--<span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">-->
                                            <!--<span style="letter-spacing:0.04em">-->
                                              <!--can help you maximize your property's potential.-->
                                              <!--Implementing personalized services that meet your needs,-->
                                              <!--with best practices and top-quality service that truly makes a difference.&nbsp;-->
                                            <!--</span>-->
                                          <!--</span>-->
                                        </p></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="comp-j7ghssjh" aria-disabled="false" class="_22oPj"><a
                                    data-testid="linkElement" data-popupid="sku2e" role="button"
                                    class="_1_kc0 getfreeevaluation"
                                    aria-haspopup="dialog" tabindex="0"><span class="_3fUtn">+</span></a></div>
                                  <div id="comp-j7ghssjg" aria-disabled="false" class="_22oPj"><a
                                    data-testid="linkElement" data-popupid="sku2e" role="button"
                                    class="_1_kc0 getfreeevaluation"
                                    aria-haspopup="dialog" tabindex="0"><span class="_3fUtn">Get free evaluation</span></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div id="comp-kjhuffrs" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                             aria-label="Contact Us">&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer id="SITE_FOOTER_WRAPPER"></footer>
      </div>
    </div>
    <div id="SCROLL_TO_BOTTOM" class="_3nfU6 ignore-focus" tabindex="-1" role="region" aria-label="bottom of page">
      &nbsp;
    </div>
  </div>
</div>
