<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" routerLink="/">
      <img
        src="./../../assets/KELSEYMANAGEMENT.png"
        style="width: 180px;height: 64px;object-fit: cover;object-position: 50% 50%;" class="_1SQyl DFk41">
    </a>
    <button (click)="toggleNavbar()" class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarsExample09"
            aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsExample09">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#comp-j7gex564">
            WHAT WE DO
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#comp-kjhqm0wa">WHY CHOOSE US
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/our-services">OUR SERVICES
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#comp-j7gi7kz8">CONTACT US
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div id="SITE_CONTAINER">
  <div id="main_MF">
    <div id="SCROLL_TO_TOP" class="_3nfU6 ignore-focus" tabindex="-1" role="region" aria-label="top of page">&nbsp;
    </div>
    <div id="BACKGROUND_GROUP">
      <div id="pageBackground_c1dmp" data-media-height-override-type="" data-media-position-override="false"
           class="_3L3Me">
        <div id="bgLayers_pageBackground_c1dmp" class="_3_wbk">
          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
          <div id="bgMedia_pageBackground_c1dmp" class="_33YMf"></div>
        </div>
      </div>
    </div>
    <div id="site-root">
      <div id="masterPage" class="mesh-layout">
        <header id="SITE_HEADER_WRAPPER">
          <div *ngIf="showMobileNavbar" id="SITE_HEADER" class="_11CDm" tabindex="-1">
            <div class="_1nb6T">
              <div class="mTQQp"></div>
            </div>
            <div class="_2WCQx">
              <div class="_3l5s9">
                <div class="_1VpWy"></div>
              </div>
              <div class="_3sXYI">
                <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                  <div data-mesh-id="SITE_HEADERinlineContent-gridContainer" data-testid="mesh-container-content">
                    <div id="MENU_AS_CONTAINER_TOGGLE" class="_3nNNS _2ZAR3" role="button" aria-haspopup="dialog"
                         aria-label="close navigation dialog" tabindex="0">
                      <div class="_1Yg_6 _2ZAR3">
                        <div class="onc0L _1R_jm"><span class="_3HSgv _2oLW8"></span><span class="_3HSgv _30Z4I"></span><span
                          class="_3HSgv _3-bhG"></span></div>
                      </div>
                    </div>
                    <div id="comp-kjhmuu8i" class="_2TxBB _3TiYw" title=""><a data-testid="linkElement"
                                                                              routerLink="/"
                                                                              target="_self" class="_2yXCL"
                                                                              tabindex="-1" data-restore-tabindex="0">
                      <div id="img_comp-kjhmuu8i" class="_1SQyl DFk41"
                           data-image-info="{&quot;containerId&quot;:&quot;comp-kjhmuu8i&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1178,&quot;height&quot;:305,&quot;uri&quot;:&quot;23447d_001090e20557417e857e354fcbb920cb~mv2.png&quot;,&quot;name&quot;:&quot;Copy%20of%20Grey%20Box%20Photography%20L&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                           data-bg-effect-name="" data-is-svg="false" data-is-svg-mask="false"
                           data-image-zoomed="" data-has-ssr-src=""
                           data-src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_340,h_88,al_c,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.png">
                        <img
                          src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_340,h_88,al_c,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.png"
                          alt="Copy%20of%20Grey%20Box%20Photography%20L"
                          style="width:170px;height:44px;object-fit:cover;object-position:50% 50%"></div>
                    </a></div>
                  </div>
                  <div id="MENU_AS_CONTAINER" tabindex="0" class="_3K_BP HcZHD _1M5DM _3V_s5 nwIMw">
                    <div id="overlay-MENU_AS_CONTAINER" class="_3S2Zm _1poaC"
                         style="opacity: 1; visibility: inherit;"></div>
                    <div id="container-MENU_AS_CONTAINER" class="_29B4p">
                      <div id="bgLayers_MENU_AS_CONTAINER" class="_3_wbk">
                        <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                        <div id="bgMedia_MENU_AS_CONTAINER" class="_33YMf"></div>
                      </div>
                      <div id="inlineContentParent-MENU_AS_CONTAINER" class="_2rHxw Gjtp0">
                        <div data-mesh-id="MENU_AS_CONTAINERinlineContent" data-testid="inline-content" class="">
                          <button (click)="toggleNavbar()"
                                  style="right: 45px;top: 15px;font-size: 40px;position: absolute;" type="button"
                                  class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div data-mesh-id="MENU_AS_CONTAINERinlineContent-gridContainer"
                               data-testid="mesh-container-content">
                            <nav id="comp-t85mur39" aria-label="Site" class="_1ZEPx">
                              <ul class="_2KEvN">
                                <li data-testid="comp-t85mur39-0" class="_3iR6X _36-RK">
                                  <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                      class="_2gQW9"><a
                                    data-testid="linkElement" data-anchor="dataItem-j6utb6th"
                                    href="/home-mobile#comp-j7gex564" class="_1kcjH"
                                    (click)="toggleNavbar()">WHAT WE DO</a></span>
                                  </div>
                                </li>
                                <li data-testid="comp-t85mur39-1" class="_3iR6X _36-RK">
                                  <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                      class="_2gQW9"><a
                                    data-testid="linkElement" data-anchor="dataItem-kjhuek4c"
                                    href="/home-mobile#comp-kjhqm0wa" target="_self" class="_1kcjH"
                                    (click)="toggleNavbar()">WHY CHOOSE US</a></span>
                                  </div>
                                </li>
                                <li data-testid="comp-t85mur39-2" class="_3iR6X _36-RK">
                                  <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                      class="_2gQW9"><a
                                    data-testid="linkElement"
                                    routerLink="/our-services" target="_self"
                                    class="_1kcjH">OUR SERVICES</a></span></div>
                                </li>
                                <li data-testid="comp-t85mur39-3" class="_3iR6X _36-RK">
                                  <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                      class="_2gQW9"><a
                                    data-testid="linkElement" data-anchor="dataItem-kjhufft4"
                                    href="/home-mobile#comp-j7gi7kz8" class="_1kcjH"
                                    (click)="toggleNavbar()">CONTACT US</a></span>
                                  </div>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main id="PAGES_CONTAINER" tabindex="-1">
          <div id="SITE_PAGES">
            <div id="c1dmp" class="_2e6ps">
              <div class="_2HjkL"></div>
              <div class="_2S9ms">
                <div id="Containerc1dmp" class="_3Mgpu">
                  <div data-mesh-id="Containerc1dmpinlineContent" data-testid="inline-content" class="">
                    <div data-mesh-id="Containerc1dmpinlineContent-gridContainer" data-testid="mesh-container-content">
                      <section id="comp-j6gmckgo" data-angle="0" data-angle-style-location="style"
                               class="">
                        <div id="bgLayers_comp-j6gmckgo" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-j6gmckgo" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div id="comp-j6gmckir" class="_1HpZ_">
                            <div id="bgLayers_comp-j6gmckir" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j6gmckir" class="_33YMf">
                                <div id="img_comp-j6gmckir" class="_1SQyl FgquG _3r8sA cNGww bgImage"
                                     data-image-info="{&quot;containerId&quot;:&quot;comp-j6gmckir&quot;,&quot;alignType&quot;:&quot;center&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:3000,&quot;height&quot;:2002,&quot;uri&quot;:&quot;23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                     data-has-bg-scroll-effect="" data-bg-effect-name="" data-is-svg="false"
                                     data-is-svg-mask="false" data-image-zoomed="" data-has-ssr-src=""
                                     data-src="https://static.wixstatic.com/media/23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.jpg/v1/fill/w_640,h_510,al_c,q_80,usm_0.66_1.00_0.01/23447d_20a420df36894f8f8fd7e2d87fdcc985~mv2.jpg">
                                  <img src="./../../assets/home-page.jpg" class="" style="height: 255px;width: 320px; object-fit: cover; object-position: 50% 50%;">
                                </div>
                              </div>
                            </div>
                            <div data-mesh-id="comp-j6gmckirinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-j6gmckirinlineContent-gridContainer"
                                   data-testid="mesh-container-content"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="comp-j82z235y">
                        <div id="bgLayers_comp-j82z235y" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-j82z235y" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div id="comp-j82z237y" class="_1HpZ_">
                            <div id="bgLayers_comp-j82z237y" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j82z237y" class="_33YMf"></div>
                            </div>
                            <div data-mesh-id="comp-j82z237yinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-j82z237yinlineContent-gridContainer"
                                   data-testid="mesh-container-content"></div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div id="comp-j6utb6sn" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                           aria-label="Our Services">&nbsp;
                      </div>
                      <section id="comp-j7gex564" data-angle="0" data-angle-style-location="style"
                               class="" style="margin-bottom: 130px">
                        <div id="bgLayers_comp-j7gex564" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-j7gex564" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                               id="comp-j7gex57b" class=" _1HpZ_">
                            <div id="bgLayers_comp-j7gex57b" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j7gex57b" class="_33YMf"></div>
                            </div>
                            <div data-mesh-id="comp-j7gex57binlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-j7gex57binlineContent-gridContainer"
                                   data-testid="mesh-container-content">
                                <div id="comp-j7gdpzlb" class="_1Z_nJ" data-testid="richTextElement"><h2 class="font_2"
                                                                                                         style="font-size:28px">
                                  <span style="color:#FFFFFF"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">What We Do</span></span>
                                </h2></div>
                                <div id="comp-k41aqqhb" class="_1Z_nJ" data-testid="richTextElement"><p class="font_8"
                                                                                                        style="line-height:1.7em;font-size:14px">
                                  <span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    class="color_11"><span style="letter-spacing:0.04em">
                                    The Kelsey Management Team has had a reputation for delivering best in class service. With operations based in Florida, Kelsey Management creates customized structures and strategies for each client’s unique and diversified needs. We strive to provide creative solutions to ensure we create a reliable partnership to suit your business. We offer full service property management, with extensive experience in homeowner associations, master planned communities, self-managed properties, and low-rise and high-rise condominiums.&nbsp;

                                    &nbsp;</span></span></span>
                                </p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="comp-j82zcvl7">
                        <div id="bgLayers_comp-j82zcvl7" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-j82zcvl7" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div id="comp-j82zcvmc" class="_1HpZ_">
                            <div id="bgLayers_comp-j82zcvmc" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j82zcvmc" class="_33YMf"></div>
                            </div>
                            <div data-mesh-id="comp-j82zcvmcinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-j82zcvmcinlineContent-gridContainer"
                                   data-testid="mesh-container-content" class="">
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7geey4n" class="hide-on-init _1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7geey4ninlineContent" data-testid="inline-content" class="">
                                    <div data-mesh-id="comp-j7geey4ninlineContent-gridContainer"
                                         data-testid="mesh-container-content">
                                      <div id="comp-j7geey4s" class="_1Z_nJ" data-testid="richTextElement"><h3
                                        class="font_5" style="font-size:23px"><span style="color:#26436B"><span
                                        style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Homeowners&nbsp;</span></span></span><span
                                        style="color:#26436B"><span style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Association</span></span></span>
                                      </h3></div>
                                      <div id="comp-j7geeufj" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.04em">
                                        Kelsey Management provides a complete scope of services to ensure seamless financial, administrative, and operational requirements for your community.
                                        &nbsp;</span></span>
                                      </p>

                                        <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                          style="letter-spacing:0.04em"><span
                                          class="wixGuard">&#8203;</span></span></span></p></div>
                                      <div id="comp-j7geey4o" aria-disabled="false" class="_22oPj"><a
                                        data-testid="linkElement"
                                        routerLink="/our-services" target="_self"
                                        class="_1_kc0"><span class="_3fUtn">+ Learn More</span></a></div>
                                    </div>
                                  </div>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7getfqn" class="hide-on-init _1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7getfqninlineContent" data-testid="inline-content" class="">
                                    <div data-mesh-id="comp-j7getfqninlineContent-gridContainer"
                                         class=" " data-testid="mesh-container-content">
                                      <div id="comp-j7getfqr1" class="_1Z_nJ" data-testid="richTextElement"><h3
                                        class="font_5" style="font-size:23px"><span style="color:#26436B"><span
                                        style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Low and Mid-Rise Condominiums</span></span></span>
                                      </h3></div>
                                      <div id="comp-j7getfqq" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.04em">
                                        Maintenance, rent collection, tenant services, and screenings - we are capable of navigating the high-level and detail-specific needs of your property.
                                      </span></span>
                                      </p></div>
                                      <div id="comp-j7getfqo" aria-disabled="false" class="_22oPj"><a
                                        data-testid="linkElement"
                                        routerLink="/our-services" target="_self"
                                        class="_1_kc0"><span class="_3fUtn">+ Learn More</span></a></div>
                                    </div>
                                  </div>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7getkmr" class="hide-on-init _1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7getkmrinlineContent" data-testid="inline-content" class="">
                                    <div data-mesh-id="comp-j7getkmrinlineContent-gridContainer"
                                         class="" data-testid="mesh-container-content">
                                      <div id="comp-j7getkmx" class="_1Z_nJ" data-testid="richTextElement"><h3
                                        class="font_5" style="font-size:23px"><span style="color:#26436B"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="font-weight:bold">High-Rise&nbsp; </span></span></span><span
                                        style="color:#26436B"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="font-weight:bold">Condominiums</span></span></span></h3></div>
                                      <div id="comp-j7getkmu" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.04em">Our Team is well-equipped to handle every need of your building’s residents. We are skilled at navigating and meeting the demands of luxury communities across Florida.</span></span>
                                      </p>

                                        <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                          style="letter-spacing:0.04em"><span
                                          class="wixGuard">&#8203;</span></span></span></p></div>
                                      <div id="comp-j7getkms" aria-disabled="false" class="_22oPj"><a
                                        data-testid="linkElement"
                                        routerLink="/our-services" target="_self"
                                        class="_1_kc0"><span class="_3fUtn">+ Learn More</span></a></div>
                                    </div>
                                  </div>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7gf5quz" class="hide-on-init _1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7gf5quzinlineContent" data-testid="inline-content" class="">
                                    <div data-mesh-id="comp-j7gf5quzinlineContent-gridContainer"
                                         class=" " data-testid="mesh-container-content">
                                      <div id="comp-j7gf5qv5" class="_1Z_nJ" data-testid="richTextElement"><h3
                                        class="font_5" style="font-size:23px"><span style="color:#26436B"><span
                                        style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Developer&nbsp;</span></span></span><span
                                        style="color:#26436B"><span style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Services</span></span></span>
                                      </h3></div>
                                      <div id="comp-j7gf5qv3" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.04em">With our expertise in full service support and management, we have a strong track record of partnering with developers to make sure their business goals become a reality.&nbsp;</span></span>
                                      </p></div>
                                      <div id="comp-j7gf5qv01" aria-disabled="false" class="_22oPj"><a
                                        data-testid="linkElement"
                                        routerLink="/our-services" target="_self"
                                        class="_1_kc0"><span class="_3fUtn">+ Learn More</span></a></div>
                                    </div>
                                  </div>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7gf5qvg" class="hide-on-init _1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7gf5qvginlineContent" data-testid="inline-content" class="">
                                    <div data-mesh-id="comp-j7gf5qvginlineContent-gridContainer"
                                         class=" " data-testid="mesh-container-content">
                                      <div id="comp-j7gf5qvl1" class="_1Z_nJ" data-testid="richTextElement"><h3
                                        class="font_5" style="font-size:23px"><span style="color:#26436B"><span
                                        style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Commercial&nbsp;</span></span></span><span
                                        style="color:#26436B"><span style="font-weight:bold"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Properties</span></span></span>
                                      </h3></div>
                                      <div id="comp-j7gf5qvk" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.04em">With a team committed to comprehensive management, we successfully operate and oversee commercial properties of all sizes to create successful outcomes for your business.</span></span>
                                      </p></div>
                                      <div id="comp-j7gf5qvh" aria-disabled="false" class="_22oPj"><a
                                        data-testid="linkElement"
                                        routerLink="/our-services" target="_self"
                                        class="_1_kc0"><span class="_3fUtn">+ Learn More</span></a></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section id="comp-j82zhvnq">
                        <div id="bgLayers_comp-j82zhvnq" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-j82zhvnq" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div id="comp-j82zhvp2" class="_1HpZ_">
                            <div id="bgLayers_comp-j82zhvp2" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j82zhvp2" class="_33YMf"></div>
                            </div>
                            <div data-mesh-id="comp-j82zhvp2inlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-j82zhvp2inlineContent-gridContainer"
                                   data-testid="mesh-container-content">
                                <div id="comp-j7ghlthx" class="_1lRal " data-angle="0"
                                     data-angle-style-location="style">
                                  <div class="_2iB5S"></div>
                                  <div data-mesh-id="comp-j7ghlthxinlineContent" data-testid="inline-content" class="">
                                    <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                         data-mesh-id="comp-j7ghlthxinlineContent-gridContainer"
                                         class="hide-on-init" data-testid="mesh-container-content">
                                      <div id="comp-j7gho0xn" class="_1Z_nJ" data-testid="richTextElement"><h2
                                        class="font_2" style="font-size:28px"><span style="color:#26436B;"><span
                                        style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif;">Partner With Us Today</span></span>
                                      </h2></div>
                                      <div id="comp-j7ghqwti1" class="_1e6g2"></div>
                                      <div id="comp-j7ghqwti" class="_1Z_nJ" data-testid="richTextElement"><p
                                        class="font_8" style="line-height:1.7em;font-size:14px">
                                        <span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                        <span style="letter-spacing:0.04em">
                                        The Kelsey Management Team understands that all properties, businesses, and circumstances are unique. Our approach is tailored and hands-on to ensure that we are of service to you in maximizing your property’s potential. We pride ourselves on being accessible, exceeding expectations, and ultimately delivering service that generates business growth for our clients.&nbsp;
                                        </span>
                                        </span>
                                        <!--<span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">-->
                                        <!--<span style="letter-spacing:0.04em">-->
                                        <!--can help you maximize your property's potential. Implementing pers-->
                                        <!--onalized services that meet your needs, with best practices and top-quali-->
                                        <!--ty service that truly makes a difference.&nbsp;-->
                                        <!--</span>-->
                                        <!--</span>-->
                                      </p></div>
                                    </div>
                                  </div>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7ghssjh" aria-disabled="false" class="hide-on-init _22oPj "
                                     data-angle="0" data-angle-style-location="style"><a
                                  data-testid="linkElement" data-popupid="sku2e" role="button"
                                  class="_1_kc0 getfreeevaluation"
                                  aria-haspopup="dialog" tabindex="0"><span class="_3fUtn">+</span></a>
                                </div>
                                <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                     id="comp-j7ghssjg" aria-disabled="false" class="hide-on-init _22oPj "
                                     data-angle="0" data-angle-style-location="style"><a
                                  data-testid="linkElement" data-popupid="sku2e" role="button"
                                  class="_1_kc0 getfreeevaluation"
                                  aria-haspopup="dialog" tabindex="0"><span
                                  class="_3fUtn">Get free evaluation</span></a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div id="comp-kjhmgj48" class="_3e89g">
                        <div data-mesh-id="comp-kjhmgj48inlineContent" data-testid="inline-content" class="">
                          <div data-mesh-id="comp-kjhmgj48inlineContent-gridContainer"
                               data-testid="mesh-container-content">
                            <form id="comp-kjhmgj4f1" class="Cdk9j">
                              <div data-mesh-id="comp-kjhmgj4f1inlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-kjhmgj4f1inlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div id="comp-kjhmgj55" class="_1Z_nJ" data-testid="richTextElement"><h5
                                    class="font_5" style="font-size:22px"><span style="font-size:22px"><span
                                    style="color:#FFFFFF"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">We Offer Complete Property Management Solutions</span></span></span>
                                  </h5>

                                    <h5 class="font_5" style="font-size:13px;line-height:2.3em"><span
                                      style="font-size:13px"><span class="color_11"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Schedule a call with our dedicated team today!</span></span></span>
                                    </h5></div>
                                  <div id="comp-kjhmgj5h" class="_31qkb _1uYgo PEjbJ">
                                    <label for="input_comp-kjhmgj5h" class="_2UrhM">Name</label>
                                    <div class="Lc5OM">
                                      <input type="text" name="first-name" id="input_comp-kjhmgj5h"
                                             class="A1_tY has-custom-focus" value=""
                                             placeholder="E.g., Kat" required="" maxlength="100">
                                    </div>
                                  </div>
                                  <!--<div id="comp-kjhmgj5s" class="_31qkb _1uYgo">-->
                                  <!--<label for="input_comp-kjhmgj5s" class="_2UrhM">Last Name</label>-->
                                  <!--<div class="Lc5OM">-->
                                  <!--<input type="text" name="last-name" id="input_comp-kjhmgj5s"-->
                                  <!--class="A1_tY has-custom-focus" value=""-->
                                  <!--placeholder="E.g., Jones" maxlength="100">-->
                                  <!--</div>-->
                                  <!--</div>-->
                                  <div id="comp-kjhmgj5v" class="_31qkb _1uYgo PEjbJ"><label for="input_comp-kjhmgj5v"
                                                                                             class="_2UrhM">Email</label>
                                    <div class="Lc5OM"><input type="email" name="email" id="input_comp-kjhmgj5v"
                                                              class="A1_tY has-custom-focus" value=""
                                                              placeholder="E.g., mail@example.com" required=""
                                                              pattern="^.+@.+\.[a-zA-Z]{2,63}$" maxlength="250"></div>
                                  </div>
                                  <div id="comp-kjhmgj5x3" class="_31qkb _1uYgo PEjbJ"><label for="input_comp-kjhmgj5x3"
                                                                                              class="_2UrhM">Phone</label>
                                    <div class="Lc5OM"><input type="tel" name="phone" id="input_comp-kjhmgj5x3"
                                                              class="A1_tY has-custom-focus" value=""
                                                              placeholder="e.g., 123 456 7890" required=""
                                                              maxlength="50"></div>
                                  </div>
                                  <div id="comp-kjhmgj601" class="_42POZ _2ugKu"><label for="textarea_comp-kjhmgj601"
                                                                                        class="_2SAi8">Message</label><textarea
                                    id="textarea_comp-kjhmgj601" class="_1qIig has-custom-focus"
                                    placeholder="Type your message here..."></textarea></div>
                                  <div id="comp-kjhmgj682" aria-disabled="false" class="_22oPj">
                                    <button data-testid="buttonElement" class="_1_kc0 getfreeevaluation"><span
                                      class="_3fUtn">Submit</span></button>
                                  </div>
                                </div>
                              </div>
                              <div class="FHREF"><label>Please leave this field empty.<input type="text" name="message"
                                                                                             autocomplete="nada"
                                                                                             tabindex="-1"
                                                                                             value=""></label></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <section id="comp-kjhqm0wa">
                        <div id="bgLayers_comp-kjhqm0wa" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_comp-kjhqm0wa" class="_33YMf"></div>
                        </div>
                        <div data-testid="columns" class="_3BQmz">
                          <div id="comp-kjhqm0za" class="_1HpZ_">
                            <div id="bgLayers_comp-kjhqm0za" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-kjhqm0za" class="_33YMf">
                                <div id="img_comp-kjhqm0za" class="_1SQyl FgquG _3r8sA cNGww bgImage"
                                     data-image-info="{&quot;containerId&quot;:&quot;comp-kjhqm0za&quot;,&quot;alignType&quot;:&quot;center&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:4692,&quot;height&quot;:3108,&quot;uri&quot;:&quot;11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                     data-has-bg-scroll-effect="" data-bg-effect-name="" data-is-svg="false"
                                     data-is-svg-mask="false" data-image-zoomed="" data-has-ssr-src=""
                                     data-src="https://static.wixstatic.com/media/11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg/v1/fill/w_640,h_1646,al_c,q_85,usm_0.66_1.00_0.01/11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg">
                                  <img
                                    src="https://static.wixstatic.com/media/11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg/v1/fill/w_640,h_1646,al_c,q_85,usm_0.66_1.00_0.01/11062b_59e2154cc2964841844a6912615cc6f2~mv2.jpeg"
                                    alt="Residential Homes"
                                    style="width: 320px; height: 823px; object-fit: cover; object-position: 50% 50%;">
                                </div>
                              </div>
                            </div>
                            <div data-mesh-id="comp-kjhqm0zainlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-kjhqm0zainlineContent-gridContainer"
                                   data-testid="mesh-container-content">
                                <div id="comp-kjhqnj3b" class="_1Z_nJ" data-testid="richTextElement"><h2 class="font_2"
                                                                                                         style="font-size:28px">
                                  <span style="color:#FFFFFF"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Why Choose Us</span></span>
                                </h2></div>
                                <div id="comp-kjhqrmqa">
                                  <div role="grid" class="_2Td_x">
                                    <div role="row" class="_2VBm0">
                                      <div role="gridcell" class="bDfMI">
                                        <div id="comp-kjhqrmsc__item1" class="_1HpZ_">
                                          <div id="bgLayers_comp-kjhqrmsc__item1" class="_3_wbk">
                                            <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                            <div id="bgMedia_comp-kjhqrmsc__item1" class="_33YMf"></div>
                                          </div>
                                          <div data-mesh-id="comp-kjhqrmsc__item1inlineContent"
                                               data-testid="inline-content" class="">
                                            <div data-mesh-id="comp-kjhqrmsc__item1inlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-kjhqrmss__item1" class="_2TxBB" title="">
                                                <div data-testid="linkElement" class="_2yXCL">
                                                  <div id="img_comp-kjhqrmss__item1" class="_1SQyl DFk41"
                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kjhqrmss__item1&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1000,&quot;height&quot;:1000,&quot;uri&quot;:&quot;23447d_c66909521f00423085e8fa68a8b6d446~mv2.png&quot;,&quot;name&quot;:&quot;Grey Box Photography Logo.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                       data-bg-effect-name="" data-is-svg="false"
                                                       data-is-svg-mask="false" data-image-zoomed=""
                                                       data-has-ssr-src=""
                                                       data-src="https://static.wixstatic.com/media/23447d_c66909521f00423085e8fa68a8b6d446~mv2.png/v1/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo.png">
                                                    <img
                                                      src="https://static.wixstatic.com/media/23447d_c66909521f00423085e8fa68a8b6d446~mv2.png/v1/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo.png"
                                                      alt="Grey Box Photography Logo.png"
                                                      style="width:60px;height:54px;object-fit:cover;object-position:50% 50%">
                                                  </div>
                                                </div>
                                              </div>
                                              <div id="comp-kjhqrmsw1__item1" class="_1Z_nJ"
                                                   data-testid="richTextElement"><h4
                                                style="font-size:19px;text-align:center"><span
                                                style="letter-spacing:0.1em"><span style="font-size:19px"><span
                                                style="color:#000000"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif">PREPAREDNESS
                                              </span>
                                              </span></span></span></span>
                                              </h4></div>
                                              <div id="comp-kjhqrmt1__item1" class="_1Z_nJ"
                                                   data-testid="richTextElement"><p class="font_8"
                                                                                    style="font-size:15px;line-height:1.5em;text-align:center">
                                                <span style="font-size:15px"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="color:#545454">We utilize our extensive experience to remain strategic and agile in our approach to challenges that arise.</span></span></span>
                                              </p></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div role="gridcell" class="bDfMI">
                                        <div id="comp-kjhqrmsc__item-j9sd2so8" class="_1HpZ_">
                                          <div id="bgLayers_comp-kjhqrmsc__item-j9sd2so8" class="_3_wbk">
                                            <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                            <div id="bgMedia_comp-kjhqrmsc__item-j9sd2so8" class="_33YMf"></div>
                                          </div>
                                          <div data-mesh-id="comp-kjhqrmsc__item-j9sd2so8inlineContent"
                                               data-testid="inline-content" class="">
                                            <div data-mesh-id="comp-kjhqrmsc__item-j9sd2so8inlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-kjhqrmss__item-j9sd2so8" class="_2TxBB" title="">
                                                <div data-testid="linkElement" class="_2yXCL">
                                                  <div id="img_comp-kjhqrmss__item-j9sd2so8" class="_1SQyl DFk41"
                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kjhqrmss__item-j9sd2so8&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1000,&quot;height&quot;:1000,&quot;uri&quot;:&quot;23447d_c6e90e2f04c94ec0ac84d99a4643fb36~mv2.png&quot;,&quot;name&quot;:&quot;Grey Box Photography Logo (2).png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                       data-bg-effect-name="" data-is-svg="false"
                                                       data-is-svg-mask="false" data-image-zoomed=""
                                                       data-has-ssr-src=""
                                                       data-src="https://static.wixstatic.com/media/23447d_c6e90e2f04c94ec0ac84d99a4643fb36~mv2.png/v1/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(2).png">
                                                    <img
                                                      src="https://static.wixstatic.com/media/23447d_c6e90e2f04c94ec0ac84d99a4643fb36~mv2.png/v1/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(2).png"
                                                      alt="Grey Box Photography Logo (2).png"
                                                      style="width:60px;height:54px;object-fit:cover;object-position:50% 50%">
                                                  </div>
                                                </div>
                                              </div>
                                              <div id="comp-kjhqrmsw1__item-j9sd2so8" class="_1Z_nJ"
                                                   data-testid="richTextElement"><h4
                                                style="font-size:19px;text-align:center"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.1em"><span style="font-size:19px"><span
                                                style="color:#000000">
                                                PRECISION
                                              </span></span></span></span></span>
                                              </h4></div>
                                              <div id="comp-kjhqrmt1__item-j9sd2so8" class="_1Z_nJ"
                                                   data-testid="richTextElement"><p class="font_8"
                                                                                    style="font-size:15px;line-height:1.5em;text-align:center">
                                                <span style="font-size:15px"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="color:#545454">
                                                Creating management and operational efficiencies is our goal - we want to positively impact your bottom line and ensure there is no waste of your valuable time or resources.

                                                </span></span></span>
                                              </p></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div role="gridcell" class="bDfMI">
                                        <div id="comp-kjhqrmsc__item-j9sd2rk2" class="_1HpZ_">
                                          <div id="bgLayers_comp-kjhqrmsc__item-j9sd2rk2" class="_3_wbk">
                                            <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                            <div id="bgMedia_comp-kjhqrmsc__item-j9sd2rk2" class="_33YMf"></div>
                                          </div>
                                          <div data-mesh-id="comp-kjhqrmsc__item-j9sd2rk2inlineContent"
                                               data-testid="inline-content" class="">
                                            <div data-mesh-id="comp-kjhqrmsc__item-j9sd2rk2inlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                              <div id="comp-kjhqrmss__item-j9sd2rk2" class="_2TxBB" title="">
                                                <div data-testid="linkElement" class="_2yXCL">
                                                  <div id="img_comp-kjhqrmss__item-j9sd2rk2" class="_1SQyl DFk41"
                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kjhqrmss__item-j9sd2rk2&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1000,&quot;height&quot;:1000,&quot;uri&quot;:&quot;23447d_79081c765ff5491f9cb99fa52ae645b1~mv2.png&quot;,&quot;name&quot;:&quot;Grey Box Photography Logo (1).png&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;crop&quot;:{&quot;x&quot;:57,&quot;y&quot;:94,&quot;width&quot;:896,&quot;height&quot;:811,&quot;svgId&quot;:&quot;909695c1e003409ba70b5561666c7c4d.svg&quot;}}}"
                                                       data-bg-effect-name="" data-is-svg="true"
                                                       data-is-svg-mask="true" data-image-zoomed=""
                                                       data-has-ssr-src=""
                                                       data-src="https://static.wixstatic.com/media/23447d_79081c765ff5491f9cb99fa52ae645b1~mv2.png/v1/crop/x_57,y_94,w_896,h_811/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(1).png">
                                                    <div class="_1ShVK" style="object-position:50% 50%">
                                                      <svg width="60" height="54" viewBox="0 0 60 54"
                                                           ref="svgcomp-kjhqrmss__item-j9sd2rk2"
                                                           key="svgcomp-kjhqrmss__item-j9sd2rk2"
                                                           id="svgcomp-kjhqrmss__item-j9sd2rk2" version="1.1" role="img"
                                                           aria-label="Grey Box Photography Logo (1).png">
                                                        <defs>
                                                          <filter id="mask-comp-kjhqrmss__item-j9sd2rk2-svg-bleach">
                                                            <feColorMatrix type="matrix" values="0 0 0 0 1
				0 0 0 0 1
				0 0 0 0 1
				0 0 0 1 0"></feColorMatrix>
                                                          </filter>
                                                          <mask id="mask-comp-kjhqrmss__item-j9sd2rk2">
                                                            <use
                                                              filter="url(#mask-comp-kjhqrmss__item-j9sd2rk2-svg-bleach)"
                                                              id="mask-comp-kjhqrmss__item-j9sd2rk2-svg-use"
                                                              xlink:href="#mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                              width="100%" height="100%" x="0" y="0"></use>
                                                          </mask>
                                                          <svg id="mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                               preserveAspectRatio="none" data-bbox="20 20 160 160"
                                                               viewBox="20 20 160 160" height="200" width="200"
                                                               xmlns="http://www.w3.org/2000/svg" data-type="shape">
                                                            <g>
                                                              <path d="M180 20v160H20V20h160z"></path>
                                                            </g>
                                                          </svg>
                                                        </defs>
                                                        <image
                                                          xlink:href="https://static.wixstatic.com/media/23447d_79081c765ff5491f9cb99fa52ae645b1~mv2.png/v1/crop/x_57,y_94,w_896,h_811/fill/w_120,h_108,al_c,usm_0.66_1.00_0.01/Grey%20Box%20Photography%20Logo%20(1).png"
                                                          key="image" data-type="image" width="60" height="54"
                                                          preserveAspectRatio="xMidYMid slice"
                                                          mask="url(#mask-comp-kjhqrmss__item-j9sd2rk2)"
                                                          data-svg-mask="mask-comp-kjhqrmss__item-j9sd2rk2-svg"
                                                          style="width: 60px; height: 54px; object-fit: cover;"></image>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div id="comp-kjhqrmsw1__item-j9sd2rk2" class="_1Z_nJ"
                                                   data-testid="richTextElement"><h4
                                                style="font-size:19px;text-align:center"><span style="font-weight:bold"><span
                                                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                style="letter-spacing:0.1em"><span style="font-size:19px"><span
                                                style="color:#000000">
                                                PERFORMANCE
                                              </span></span></span></span></span>
                                              </h4></div>
                                              <div id="comp-kjhqrmt1__item-j9sd2rk2" class="_1Z_nJ"
                                                   data-testid="richTextElement"><p class="font_8"
                                                                                    style="font-size:15px;line-height:1.5em;text-align:center">
                                                <span style="font-size:15px"><span
                                                  style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                                  style="color:#545454">
                                                Integrity is at the heart of our operation - trust is paramount and we never lose sight of advocating for your business needs and producing successful results.
                                                </span></span></span>
                                              </p></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div id="comp-kjhuffrs" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                           aria-label="Contact Us">&nbsp;
                      </div>
                      <div id="comp-kjhuek2y" class="_3nfU6 ignore-focus" tabindex="-1" role="region"
                           aria-label="Why Choose Us?">&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer id="SITE_FOOTER_WRAPPER">

        </footer>
        <a href="/home-mobile#SCROLL_TO_TOP">
          <div *ngIf="showBackToTopButton" id="BACK_TO_TOP_BUTTON" class="_3jwz6 _1vN1s" data-testid="backToTopRoot">
            <div class="_33he9" data-testid="backToTopSvg"><!--?xml version="1.0" encoding="UTF-8"?-->
              <svg preserveAspectRatio="xMidYMid meet" data-bbox="20 20 160 160" xmlns="http://www.w3.org/2000/svg"
                   viewBox="20 20 160 160" height="100%" width="100%" data-type="color" role="img">
                <defs>
                  <style>#BACK_TO_TOP_BUTTON svg [data-color="1"] {
                    fill: #FFFFFF;
                  }

                  #BACK_TO_TOP_BUTTON svg [data-color="2"] {
                    fill: #000000;
                  }

                  #BACK_TO_TOP_BUTTON svg [data-color="3"] {
                    fill: #C7C7C7;
                  }</style>
                </defs>
                <g fill-rule="evenodd">
                  <path fill-rule="nonzero" fill="#FFFFFF"
                        d="M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80z"
                        data-color="1"></path>
                  <path fill="#566FB8"
                        d="M66.066 116L62 111.64 100.151 74 138 111.668 133.953 116l-33.825-32.3L66.066 116z"
                        data-color="2"></path>
                  <path fill-rule="nonzero" fill="#6D8FF1"
                        d="M100 24c-41.974 0-76 34.026-76 76s34.026 76 76 76 76-34.026 76-76-34.026-76-76-76zm0-4c44.183 0 80 35.817 80 80s-35.817 80-80 80-80-35.817-80-80 35.817-80 80-80z"
                        data-color="3"></path>
                </g>
              </svg>
            </div>
          </div>
        </a>
        <div class="NblT8 IqC5b iUzEp" style="position:fixed;visibility:hidden" id="QUICK_ACTION_BAR">
          <div class="_1h6Py"></div>
        </div>
      </div>
    </div>
    <div id="SCROLL_TO_BOTTOM" class="_3nfU6 ignore-focus" tabindex="-1" role="region" aria-label="bottom of page">
      &nbsp;
    </div>
  </div>
</div>
