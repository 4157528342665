<ng-container *ngIf="isMobile;else desktop">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="#">
        <img
          src="./../../assets/KELSEYMANAGEMENT.png"
          style="width: 180px;height: 64px;object-fit: cover;object-position: 50% 50%;" class="_1SQyl DFk41">
      </a>
      <button (click)="toggleNavbar()" class="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarsExample09"
              aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExample09">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#comp-j7gex564">
              WHAT WE DO
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#comp-kjhqm0wa">WHY CHOOSE US
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/our-services">OUR SERVICES
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#comp-j7gi7kz8">CONTACT US
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <div id="SITE_CONTAINER">
    <div id="main_MF">
      <div id="site-root">
        <div id="masterPage" class="mesh-layout">
          <header id="SITE_HEADER_WRAPPER">
            <div *ngIf="showMobileNavbar" id="SITE_HEADER" class="_11CDm" tabindex="-1">
              <div class="_1nb6T">
                <div class="mTQQp"></div>
              </div>
              <div class="_2WCQx">
                <div class="_3l5s9">
                  <div class="_1VpWy"></div>
                </div>
                <div class="_3sXYI">
                  <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                    <div data-mesh-id="SITE_HEADERinlineContent-gridContainer" data-testid="mesh-container-content">
                      <div id="MENU_AS_CONTAINER_TOGGLE" class="_3nNNS _2ZAR3" role="button" aria-haspopup="dialog"
                           aria-label="close navigation dialog" tabindex="0">
                        <div class="_1Yg_6 _2ZAR3">
                          <div class="onc0L _1R_jm"><span class="_3HSgv _2oLW8"></span><span
                            class="_3HSgv _30Z4I"></span><span
                            class="_3HSgv _3-bhG"></span></div>
                        </div>
                      </div>
                      <div id="comp-kjhmuu8i" class="_2TxBB _3TiYw" title=""><a data-testid="linkElement"
                                                                                routerLink="/"
                                                                                target="_self" class="_2yXCL"
                                                                                tabindex="-1" data-restore-tabindex="0">
                        <div id="img_comp-kjhmuu8i" class="_1SQyl DFk41"
                             data-image-info="{&quot;containerId&quot;:&quot;comp-kjhmuu8i&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1178,&quot;height&quot;:305,&quot;uri&quot;:&quot;23447d_001090e20557417e857e354fcbb920cb~mv2.png&quot;,&quot;name&quot;:&quot;Copy%20of%20Grey%20Box%20Photography%20L&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                             data-bg-effect-name="" data-is-svg="false" data-is-svg-mask="false"
                             data-image-zoomed="" data-has-ssr-src=""
                             data-src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_340,h_88,al_c,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.png">
                          <img
                            src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_340,h_88,al_c,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.png"
                            alt="Copy%20of%20Grey%20Box%20Photography%20L"
                            style="width:170px;height:44px;object-fit:cover;object-position:50% 50%"></div>
                      </a></div>
                    </div>
                    <div id="MENU_AS_CONTAINER" tabindex="0" class="_3K_BP HcZHD _1M5DM _3V_s5 nwIMw">
                      <div id="overlay-MENU_AS_CONTAINER" class="_3S2Zm _1poaC"
                           style="opacity: 1; "></div>
                      <div id="container-MENU_AS_CONTAINER" class="_29B4p" style="">
                        <div id="bgLayers_MENU_AS_CONTAINER" class="_3_wbk">
                          <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                          <div id="bgMedia_MENU_AS_CONTAINER" class="_33YMf"></div>
                        </div>
                        <div id="inlineContentParent-MENU_AS_CONTAINER" class="_2rHxw Gjtp0">
                          <div data-mesh-id="MENU_AS_CONTAINERinlineContent" data-testid="inline-content" class="">
                            <button (click)="toggleNavbar()"
                                    style="right: 45px;top: 15px;font-size: 40px;position: absolute;" type="button"
                                    class="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <div data-mesh-id="MENU_AS_CONTAINERinlineContent-gridContainer"
                                 data-testid="mesh-container-content">
                              <nav id="comp-t85mur39" aria-label="Site" class="_1ZEPx">
                                <ul class="_2KEvN">
                                  <li data-testid="comp-t85mur39-0" class="_3iR6X _36-RK">
                                    <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                        class="_2gQW9"><a
                                      data-testid="linkElement" data-anchor="dataItem-j6utb6th"
                                      href="/home-mobile#comp-j7gex564" class="_1kcjH">WHAT WE DO</a></span>
                                    </div>
                                  </li>
                                  <li data-testid="comp-t85mur39-1" class="_3iR6X _36-RK">
                                    <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                        class="_2gQW9"><a
                                      data-testid="linkElement" data-anchor="dataItem-kjhuek4c"
                                      href="/home-mobile#comp-kjhqm0wa" target="_self" class="_1kcjH">WHY CHOOSE US</a></span>
                                    </div>
                                  </li>
                                  <li data-testid="comp-t85mur39-2" class="_3iR6X _36-RK">
                                    <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                        class="_2gQW9"><a
                                      data-testid="linkElement"
                                      routerLink="/our-services" target="_self"
                                      class="_1kcjH">OUR SERVICES</a></span></div>
                                  </li>
                                  <li data-testid="comp-t85mur39-3" class="_3iR6X _36-RK">
                                    <div data-testid="itemWrapper" class="_1VrYi"><span data-testid="linkWrapper"
                                                                                        class="_2gQW9"><a
                                      data-testid="linkElement" data-anchor="dataItem-kjhufft4"
                                      href="/home-mobile#comp-j7gi7kz8" class="_1kcjH">CONTACT US</a></span>
                                    </div>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main id="PAGES_CONTAINER" tabindex="-1">
            <div id="SITE_PAGES">
              <div id="ctuyt" class="_2e6ps">
                <div class="_2HjkL"></div>
                <div class="_2S9ms">
                  <div id="Containerctuyt" class="_3Mgpu">
                    <div data-mesh-id="ContainerctuytinlineContent" data-testid="inline-content" class="">
                      <div data-mesh-id="ContainerctuytinlineContent-gridContainer"
                           data-testid="mesh-container-content">
                        <section id="comp-j6upp8g3" data-angle="0" data-angle-style-location="style"
                                 style="" class="animating-screenIn-exit">
                          <div id="bgLayers_comp-j6upp8g3" class="_3_wbk">
                            <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j6upp8g3" class="_33YMf"></div>
                          </div>
                          <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                               data-testid="columns" class="hide-on-init _3BQmz">
                            <div id="comp-j6upp8jn" class="_1HpZ_">
                              <div id="bgLayers_comp-j6upp8jn" class="_3_wbk">
                                <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j6upp8jn" class="_33YMf">
                                  <img class="_1SQyl FgquG _3r8sA cNGww bgImage"
                                       src="https://static.wixstatic.com/media/23447d_7b67aa10ff414edeaebd295468ec7a7a~mv2.jpg/v1/fill/w_640,h_278,al_c,q_80,usm_0.66_1.00_0.01/23447d_7b67aa10ff414edeaebd295468ec7a7a~mv2.webp"
                                       alt="shutterstock_1209830590.jpg"
                                       style="width: 320px; height: 139px; object-fit: cover; object-position: 50% 50%;">
                                </div>
                              </div>
                              <div data-mesh-id="comp-j6upp8jninlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j6upp8jninlineContent-gridContainer"
                                     data-testid="mesh-container-content"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section id="comp-j82zjys5">
                          <div id="bgLayers_comp-j82zjys5" class="_3_wbk">
                            <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                            <div id="bgMedia_comp-j82zjys5" class="_33YMf"></div>
                          </div>
                          <div data-testid="columns" class="_3BQmz">
                            <div id="comp-j82zjytm" class="_1HpZ_">
                              <div id="bgLayers_comp-j82zjytm" class="_3_wbk">
                                <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                <div id="bgMedia_comp-j82zjytm" class="_33YMf"></div>
                              </div>
                              <div data-mesh-id="comp-j82zjytminlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="comp-j82zjytminlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                  <div id="comp-j82zk6ca" class="_1lRal animating-screenIn-exit" data-angle="0"
                                       data-angle-style-location="style" style="">
                                    <div class="_2iB5S"></div>
                                    <div data-mesh-id="comp-j82zk6cainlineContent" data-testid="inline-content"
                                         class="">
                                      <div class="" data-mesh-id="comp-j82zk6cainlineContent-gridContainer"
                                           data-testid="mesh-container-content">
                                        <div id="comp-j82zk6cd" class="_1Z_nJ" data-testid="richTextElement"><h1
                                          class="font_0" style="line-height:1em;font-size:28px"><span
                                          style="color:#FFFFFF;">
                                          <span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Our Services</span></span>
                                        </h1></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bv" class="hide-on-init  _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_5"
                                                   style="line-height:1.2em;font-size:23px"><span
                                    style="font-weight:bold"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                                    style="letter-spacing:0.05em"><span
                                    class="color_24">{{listing[0]['heading']}}</span></span></span></span>
                                  </p></div>
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bx" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style="">
                                    <p class="font_8" style="line-height:1.7em;font-size:14px">
                                    <span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                    <span style="letter-spacing:0.03em">
                                        {{listing[0]['subheading']}}
                                    </span>
                                  </span>
                                    </p>

                                    <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em"><span class="wixGuard">&#8203;</span></span></span>&#8203;
                                    </p>

                                    <ul class="font_8" style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:14px">
                                      <li style="line-height:2em" *ngFor="let point of listing[0]['points']">
                                        <p class="font_8" style="line-height:2em;font-size:14px">
                                          <span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                            {{point}}
                                          </span>
                                        </p>
                                      </li>
                                    </ul>

                                    <p class="font_8" style="font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      class="wixGuard">&#8203;</span></span></p>

                                    <p class="font_8" style="font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      class="wixGuard">&#8203;</span></span></p></div>

                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bz" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_5"
                                                   style="line-height:1.2em;font-size:23px"><span
                                    style="font-weight:bold"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span class="color_24"><span
                                    style="letter-spacing:0.05em">{{listing[1]['heading']}}</span></span></span></span>
                                  </p></div>
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6c1" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_8"
                                                   style="line-height:1.7em;font-size:14px"><span
                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    style="letter-spacing:0.03em">{{listing[1]['subheading']}}</span></span>
                                  </p>

                                    <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em"><span class="wixGuard">&#8203;</span></span></span>
                                    </p>

                                    <ul class="font_8"
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:14px">

                                      <li style="line-height:2em" *ngFor="let point of listing[1]['points']">
                                        <p class="font_8" style="line-height:2em;font-size:14px"><span
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif">{{point}}</span>
                                        </p>
                                      </li>

                                    </ul>
                                  </div>

                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6c8" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_5"
                                                   style="line-height:1.2em;font-size:23px"><span
                                    style="font-weight:bold"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span class="color_24"><span
                                    style="letter-spacing:0.05em">{{listing[2]['heading']}}</span></span></span></span></p></div>
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bh" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_8"
                                                   style="line-height:1.7em;font-size:14px"><span
                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    style="letter-spacing:0.03em">{{listing[2]['subheading']}}</span></span>
                                  </p>

                                    <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em"><span class="wixGuard">&#8203;</span></span></span>
                                    </p>

                                    <ul class="font_8" style="font-size:14px">
                                      <li *ngFor="let point of listing[2]['points']"
                                        style="font-family: avenir-lt-w01_35-light1475496, sans-serif; letter-spacing: 0.03em; line-height: 2em;">
                                        {{point}}
                                      </li>
                                    </ul>
                                  </div>

                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bn" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_5"
                                                   style="line-height:1.2em;font-size:23px"><span
                                    style="font-weight:bold"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span class="color_24"><span
                                    style="letter-spacing:0.05em">{{listing[3]['heading']}}</span></span></span></span></p>
                                  </div>
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bp" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_8"
                                                   style="line-height:1.7em;font-size:14px"><span
                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    style="letter-spacing:0.03em">{{listing[3]['subheading']}}&nbsp;</span></span>
                                  </p>

                                    <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em"><span class="wixGuard">&#8203;</span></span></span>
                                    </p>

                                    <ul class="font_8"
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:14px">
                                      <li style="line-height:2em" *ngFor="let point of listing[3]['points']">
                                        {{point}}
                                      </li>

                                    </ul>
                                  </div>

                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6br" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_5"
                                                   style="line-height:1.2em;font-size:23px"><span
                                    style="font-weight:bold"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span class="color_24"><span
                                    style="letter-spacing:0.05em">{{listing[4]['heading']}}</span></span></span></span></p>
                                  </div>
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-j82zk6bt" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_8"
                                                   style="line-height:1.7em;font-size:14px"><span
                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    style="letter-spacing:0.03em">{{listing[4]['subheading']}}</span></span>
                                  </p>

                                    <p class="font_8" style="line-height:1.7em;font-size:14px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em"><span class="wixGuard">&#8203;</span></span></span>
                                    </p>

                                    <ul class="font_8"
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:14px">
                                      <li style="line-height:2em" *ngFor="let point of listing[4]['points']">
                                        <p class="font_8" style="line-height:2em;font-size:14px"><span
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif">{{point}}</span>
                                        </p>
                                      </li>
                                    </ul>
                                  </div>

                                  <!--Top texr-->
                                  <div animateOnScroll animationName="animate__animated animate__slideInUp animated"
                                       id="comp-kjksef1f" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                       data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                       style=""><p class="font_8"
                                                   style="font-size:15px;line-height:1.7em"><span
                                    style="font-size:15px"><span
                                    style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                    style="letter-spacing:0.03em">
                                    &nbsp;Kelsey Management provides an array of services based on client needs. All property management services are personalized and customized - you get everything you need, and nothing that you don’t.
                                  </span></span></span>
                                  </p>

                                    <p class="font_8" style="font-size:15px;line-height:1.7em"><span
                                      style="font-size:15px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">
                                      Kelsey Management works to deliver reliable and cost effective services to a wide variety of properties across Florida, including:</span></span></span>
                                    </p></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer style="margin-top: 60px!important;" id="SITE_FOOTER_WRAPPER">
          </footer>
          <a href="/our-services#SCROLL_TO_TOP">
            <div id="BACK_TO_TOP_BUTTON" *ngIf="showBackToTopButton" class="_3jwz6 _1vN1s" data-testid="backToTopRoot">
              <div class="_33he9" data-testid="backToTopSvg"><!--?xml version="1.0" encoding="UTF-8"?-->
                <svg preserveAspectRatio="xMidYMid meet" data-bbox="20 20 160 160" xmlns="http://www.w3.org/2000/svg"
                     viewBox="20 20 160 160" height="100%" width="100%" data-type="color" role="img">
                  <defs>
                    <style>#BACK_TO_TOP_BUTTON svg [data-color="1"] {
                      fill: #FFFFFF;
                    }

                    #BACK_TO_TOP_BUTTON svg [data-color="2"] {
                      fill: #000000;
                    }

                    #BACK_TO_TOP_BUTTON svg [data-color="3"] {
                      fill: #C7C7C7;
                    }</style>
                  </defs>
                  <g fill-rule="evenodd">
                    <path fill-rule="nonzero" fill="#FFFFFF"
                          d="M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80z"
                          data-color="1"></path>
                    <path fill="#566FB8"
                          d="M66.066 116L62 111.64 100.151 74 138 111.668 133.953 116l-33.825-32.3L66.066 116z"
                          data-color="2"></path>
                    <path fill-rule="nonzero" fill="#6D8FF1"
                          d="M100 24c-41.974 0-76 34.026-76 76s34.026 76 76 76 76-34.026 76-76-34.026-76-76-76zm0-4c44.183 0 80 35.817 80 80s-35.817 80-80 80-80-35.817-80-80 35.817-80 80-80z"
                          data-color="3"></path>
                  </g>
                </svg>
              </div>
            </div>
          </a>
          <div class="NblT8 IqC5b iUzEp" style="position:fixed;visibility:hidden" id="QUICK_ACTION_BAR">
            <div class="_1h6Py"></div>
          </div>
        </div>
      </div>
      <div id="SCROLL_TO_BOTTOM" class="_3nfU6 ignore-focus" tabindex="-1" role="region" aria-label="bottom of page">
        &nbsp;
      </div>
    </div>
  </div>
</ng-container>
<ng-template #desktop>
  <div id="SITE_CONTAINER">
    <div id="main_MF">
      <div id="site-root">
        <div id="masterPage" class="mesh-layout">
          <div id="SITE_HEADER" class="_11CDm" tabindex="-1">
            <div class="_1nb6T">
              <div class="mTQQp"></div>
            </div>
            <div class="_2WCQx">
              <div class="_3l5s9">
                <div class="_1VpWy"></div>
              </div>
              <div class="_3sXYI">
                <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                  <div data-mesh-id="SITE_HEADERinlineContent-gridContainer" data-testid="mesh-container-content">
                    <div id="comp-kjhmuu8i" class="_2TxBB _3TiYw" title=""><a data-testid="linkElement"
                                                                              routerLink="/"
                                                                              target="_self" class="_2yXCL">
                      <!--<wix-image id="img_comp-kjhmuu8i" class="_1SQyl DFk41"-->
                      <!--data-image-info="{&quot;containerId&quot;:&quot;comp-kjhmuu8i&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:1178,&quot;height&quot;:305,&quot;uri&quot;:&quot;23447d_001090e20557417e857e354fcbb920cb~mv2.png&quot;,&quot;name&quot;:&quot;Copy%20of%20Grey%20Box%20Photography%20L&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"-->
                      <!--data-bg-effect-name="" data-is-svg="false" data-is-svg-mask="false" data-image-zoomed=""-->
                      <!--data-has-ssr-src=""-->
                      <!--data-src="https://static.wixstatic.com/media/23447d_001090e20557417e857e354fcbb920cb~mv2.png/v1/fill/w_245,h_64,al_c,q_85,usm_0.66_1.00_0.01/Copy%2520of%2520Grey%2520Box%2520Photography%2520L.webp">-->
                      <img class="_1SQyl DFk41"
                           src="./../../assets/KELSEYMANAGEMENT.png"
                           alt="Copy%20of%20Grey%20Box%20Photography%20L"
                           style="width:180px;height:64px;object-fit:cover;object-position:50% 50%">
                      <!--</wix-image>-->
                    </a></div>
                    <div id="comp-j6gqwjnf" class="_13c26 _2cbNh hidden-during-prewarmup" dir="ltr"
                         data-stretch-buttons-to-menu-width="true" data-same-width-buttons="false"
                         data-num-items="4" data-menuborder-y="0" data-menubtn-border="0"
                         data-ribbon-els="0" data-label-pad="0" data-ribbon-extra="0"
                         data-dropalign="center" style=" overflow-x: visible;"
                         data-dropmode="dropDown">
                      <nav class="zmzdJ" id="comp-j6gqwjnfnavContainer" aria-label="Site">
                        <ul class="_3ryjj" id="comp-j6gqwjnfitemsContainer" style="text-align:center"
                            data-marginallchildren="true">
                          <li id="comp-j6gqwjnf0" data-direction="ltr" data-listposition="left"
                              data-data-id="dataItem-kjhmd1522" data-state="menu false  link" data-index="0"
                              class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                              style="width: 142px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; ">
                            <a data-testid="linkElement" data-anchor="dataItem-j6utb6th"
                               href="#comp-j7gex564" class="_25Cim"
                               aria-haspopup="false">
                              <div class="_3zfdd">
                                <div class="" style="text-align:left"><p class="_3YCIf"
                                                                         style="text-align: left; line-height: 50px;"
                                                                         id="comp-j6gqwjnf0label">WHAT WE DO</p></div>
                              </div>
                            </a></li>
                          <li id="comp-j6gqwjnf1" data-direction="ltr" data-listposition="center"
                              data-data-id="dataItem-kjjq6ro0" data-state="menu false  link" data-index="1"
                              class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                              style="width: 170px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; ">
                            <a data-testid="linkElement" data-anchor="dataItem-kjhuek4c"
                               href="#comp-kjhqm0wa" class="_25Cim"
                               aria-haspopup="false">
                              <div class="_3zfdd">
                                <div class="" style="text-align:left"><p class="_3YCIf"
                                                                         style="text-align: left; line-height: 50px;"
                                                                         id="comp-j6gqwjnf1label">WHY CHOOSE US</p>
                                </div>
                              </div>
                            </a></li>
                          <li id="comp-j6gqwjnf2" data-direction="ltr" data-listposition="center"
                              data-data-id="dataItem-kjjqqocq" data-state="menu false  link" data-index="2"
                              class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                              style="width: 148px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; ">
                            <a data-testid="linkElement" routerLink="/our-services"
                               target="_self" class="_25Cim" aria-haspopup="false">
                              <div class="_3zfdd">
                                <div class="" style="text-align:left"><p class="_3YCIf"
                                                                         style="text-align: left; line-height: 50px;"
                                                                         id="comp-j6gqwjnf2label">OUR SERVICES</p></div>
                              </div>
                            </a></li>
                          <li id="comp-j6gqwjnf3" data-direction="ltr" data-listposition="right"
                              data-data-id="dataItem-kjmkaice" data-state="menu false  link" data-index="3"
                              class="_3vxeZ _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="false"
                              style="width: 139px; height: 50px; position: relative; box-sizing: border-box; overflow: visible; ">
                            <a data-testid="linkElement" data-anchor="dataItem-kjhufft4"
                               href="#comp-j7gi7kz8" class="_25Cim"
                               aria-haspopup="false">
                              <div class="_3zfdd">
                                <div class="" style="text-align:left"><p class="_3YCIf"
                                                                         style="text-align: left; line-height: 50px;"
                                                                         id="comp-j6gqwjnf3label">CONTACT US</p></div>
                              </div>
                            </a></li>
                          <li id="comp-j6gqwjnf__more__" data-direction="ltr" data-listposition="right"
                              data-state="menu false  header" data-index="__more__" data-dropdown="false"
                              class="_2LjR0 _2TFvc" data-original-gap-between-text-and-btn="10" aria-hidden="true"
                              style="height: 0px; overflow: hidden; position: absolute; visibility: hidden;">
                            <div data-testid="linkElement" class="_25Cim" tabindex="0" aria-haspopup="false">
                              <div class="_3zfdd">
                                <div class="" style="text-align:left"><p class="_3YCIf" style="text-align:left"
                                                                         id="comp-j6gqwjnf__more__label" tabindex="-1">
                                  More</p></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="GQcif" id="comp-j6gqwjnfdropWrapper" data-dropalign="center"
                             data-dropdown-shown="false">
                          <ul class="_3u_t0" id="comp-j6gqwjnfmoreContainer"></ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <header id="SITE_HEADER_WRAPPER">
            <div id="pinnedMiddleRight">
              <div id="comp-j70tm1gq-pinned-layer" class="_1w0tU">
                <div id="comp-j70tm1gq" class="">
                  <a href="our-services#SCROLL_TO_TOP" class="_3lfWv">
                    <div data-testid="svgRoot-comp-j70tm1gq" class="_36XXs _3Qjwl">
                      <svg preserveAspectRatio="xMidYMid meet" data-bbox="6.443 47.497 187.114 105.008"
                           xmlns="http://www.w3.org/2000/svg" viewBox="6.443 47.497 187.114 105.008" role="img"
                           aria-labelledby="svgcid--qsip5ehmzy0q"><title id="svgcid--qsip5ehmzy0q">Top of Page</title>
                        <g>
                          <path
                            d="M174.476 51.261l-74.704 74.193L25.578 50.75c-4.287-4.316-11.566-4.341-15.882-.054s-4.341 11.565-.055 15.882l82.107 82.673c4.287 4.316 11.566 4.341 15.882.055l82.673-82.107c4.316-4.287 4.341-11.566.055-15.882s-11.235-4.342-15.882-.056z"></path>
                        </g>
                      </svg>
                    </div>
                  </a></div>
              </div>
            </div>
          </header>
          <main id="PAGES_CONTAINER" tabindex="-1">
            <div id="SITE_PAGES">
              <div class="_3a3qX">
                <div id="ctuyt" class="_2e6ps">
                  <div class="_2HjkL"></div>
                  <div class="_2S9ms">
                    <div id="Containerctuyt" class="_3Mgpu">
                      <div data-mesh-id="ContainerctuytinlineContent" data-testid="inline-content" class="">
                        <div data-mesh-id="ContainerctuytinlineContent-gridContainer"
                             data-testid="mesh-container-content">
                          <section id="comp-j6upp8g3">
                            <div id="bgLayers_comp-j6upp8g3" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j6upp8g3" class="_33YMf"></div>
                            </div>
                            <div data-testid="columns" class="_3BQmz">
                              <div id="comp-j6upp8jn" class="_1HpZ_">
                                <div id="bgLayers_comp-j6upp8jn" class="_3_wbk">
                                  <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                  <div id="bgMedia_comp-j6upp8jn" class="_33YMf">
                                    <img class="_1SQyl FgquG _3r8sA cNGww bgImage"
                                         src="https://static.wixstatic.com/media/23447d_7b67aa10ff414edeaebd295468ec7a7a~mv2.jpg/v1/fill/w_1000,h_460,al_c,q_85/23447d_7b67aa10ff414edeaebd295468ec7a7a~mv2.webp"
                                         alt="shutterstock_1209830590.jpg"
                                         style="width: 1160px; height: 534px; object-fit: cover; object-position: 50% 50%;">

                                  </div>
                                </div>
                                <div data-mesh-id="comp-j6upp8jninlineContent" data-testid="inline-content" class="">
                                  <div data-mesh-id="comp-j6upp8jninlineContent-gridContainer"
                                       data-testid="mesh-container-content"></div>
                                </div>
                              </div>
                              <div id="comp-j6upp8jq" class="_1HpZ_">
                                <div id="bgLayers_comp-j6upp8jq" class="_3_wbk">
                                  <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                  <div id="bgMedia_comp-j6upp8jq" class="_33YMf"></div>
                                </div>
                                <div data-mesh-id="comp-j6upp8jqinlineContent" data-testid="inline-content" class="">
                                  <div data-mesh-id="comp-j6upp8jqinlineContent-gridContainer"
                                       data-testid="mesh-container-content"></div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section id="comp-j82zjys5">
                            <div id="bgLayers_comp-j82zjys5" class="_3_wbk">
                              <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                              <div id="bgMedia_comp-j82zjys5" class="_33YMf"></div>
                            </div>
                            <div data-testid="columns" class="_3BQmz">
                              <div id="comp-j82zjytm" class="_1HpZ_">
                                <div id="bgLayers_comp-j82zjytm" class="_3_wbk">
                                  <div data-testid="colorUnderlay" class="P0kib FgquG"></div>
                                  <div id="bgMedia_comp-j82zjytm" class="_33YMf"></div>
                                </div>
                                <div data-mesh-id="comp-j82zjytminlineContent" data-testid="inline-content" class="">
                                  <div data-mesh-id="comp-j82zjytminlineContent-gridContainer"
                                       data-testid="mesh-container-content">
                                    <div animateOnScroll animationName="animate__animated animate__fadeInLeft animated"
                                         id="comp-j82zk6ca" class="hide-on-init _1lRal animating-screenIn-exit"
                                         data-angle="0"
                                         data-angle-style-location="style" style="">
                                      <div class="_2iB5S"></div>
                                      <div data-mesh-id="comp-j82zk6cainlineContent" data-testid="inline-content"
                                           class="">
                                        <div data-mesh-id="comp-j82zk6cainlineContent-gridContainer"
                                             data-testid="mesh-container-content">
                                          <div id="comp-j82zk6cd" class="_1Z_nJ" data-testid="richTextElement"><h1
                                            class="font_0" style="line-height:1em;font-size:66px"><span
                                            style="color:#FFFFFF;"><span
                                            style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Our Services</span></span>
                                          </h1></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-kjksef1f" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_8"
                                                     style="font-size:18px; line-height:1.7em"><span
                                      style="font-size:18px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">Kelsey Management provides an array of services based on client needs. All property management services are personalized and customized - you get everything you need, and nothing that you don’t.&nbsp;</span></span></span>
                                    </p>

                                      <p class="font_8" style="font-size:18px; line-height:1.7em"><span
                                        style="font-size:18px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em">Kelsey Management works to deliver reliable and cost effective services to a wide variety of properties across Florida, including:</span></span></span>
                                      </p>
                                    </div>



                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bv" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style="">
                                      <p class="font_5" style="line-height:1.2em;font-size:28px">
                                        <span style="font-weight:bold">
                                          <span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">
                                            <span style="letter-spacing:0.05em">
                                              <span class="color_24">
                                                {{listing[0]['heading']}}
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bx" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style="">
                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em">
                                      {{listing[0]['subheading']}}
                                      </span></span>
                                      </p>

                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em"><span
                                        class="wixGuard">&#8203;</span></span></span>&#8203;
                                      </p>

                                      <ul class="font_8"
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:16px">
                                        <li style="line-height:2em" *ngFor="let point of listing[0]['points']">
                                          <p class="font_8" style="line-height:2em;font-size:16px"><span
                                            style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                            {{point}}
                                          </span>
                                          </p>
                                        </li>

                                      </ul>

                                      <p class="font_8" style="font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        class="wixGuard">&#8203;</span></span></p>

                                      <p class="font_8" style="font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        class="wixGuard">&#8203;</span></span></p></div>

                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bz" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_5"
                                                     style="line-height:1.2em;font-size:28px"><span
                                      style="font-weight:bold"><span
                                      style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                                      class="color_24"><span
                                      style="letter-spacing:0.05em">{{listing[1]['heading']}}</span></span></span></span>
                                    </p></div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6c1" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_8"
                                                     style="line-height:1.7em;font-size:16px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">
                                      {{listing[1]['subheading']}}
                                    </span></span>
                                    </p>

                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em"><span
                                        class="wixGuard">&#8203;</span></span></span>
                                      </p>

                                      <ul class="font_8"
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:16px">

                                        <li style="line-height:2em" *ngFor="let point of listing[1]['points']">
                                          <p class="font_8" style="line-height:2em;font-size:16px"><span
                                            style="font-family:avenir-lt-w01_35-light1475496,sans-serif">{{point}}</span>
                                          </p>
                                        </li>

                                      </ul>
                                    </div>

                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6c8" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_5"
                                                     style="line-height:1.2em;font-size:28px"><span
                                      style="font-weight:bold"><span
                                      style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                                      class="color_24"><span
                                      style="letter-spacing:0.05em">{{listing[2]['heading']}}</span></span></span></span></p>
                                    </div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bh" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_8"
                                                     style="line-height:1.7em;font-size:16px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">{{listing[2]['subheading']}}</span></span>
                                    </p>

                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em"><span
                                        class="wixGuard">&#8203;</span></span></span>
                                      </p>

                                      <ul class="font_8" style="font-size:16px">

                                        <li *ngFor="let point of listing[2]['points']"
                                          style="font-family: avenir-lt-w01_35-light1475496, sans-serif; letter-spacing: 0.03em; line-height: 2em;">
                                          <p class="font_8" style="line-height:2em;font-size:16px"><span
                                            style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                                            {{point}}</span>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>

                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bn" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_5"
                                                     style="line-height:1.2em;font-size:28px"><span
                                      style="font-weight:bold"><span
                                      style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                                      class="color_24"><span
                                      style="letter-spacing:0.05em">{{listing[3]['heading']}}</span></span></span></span></p>
                                    </div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bp" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_8"
                                                     style="line-height:1.7em;font-size:16px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">
                                    {{listing[3]['subheading']}}
                                    </span></span>
                                    </p>

                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em"><span
                                        class="wixGuard">&#8203;</span></span></span>
                                      </p>

                                      <ul class="font_8"
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:16px">
                                        <li style="line-height:2em" *ngFor="let point of listing[3]['points']">
                                          {{point}}
                                        </li>
                                      </ul>
                                    </div>

                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6br" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_5"
                                                     style="line-height:1.2em;font-size:28px"><span
                                      style="font-weight:bold"><span
                                      style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                                      class="color_24"><span
                                      style="letter-spacing:0.05em">{{listing[4]['heading']}}</span></span></span></span>
                                    </p>
                                    </div>
                                    <div animateOnScroll animationName="animate__animated animate__fadeIn animated"
                                         id="comp-j82zk6bt" class="hide-on-init _1Z_nJ animating-screenIn-exit"
                                         data-testid="richTextElement" data-angle="0" data-angle-style-location="style"
                                         style=""><p class="font_8"
                                                     style="line-height:1.7em;font-size:16px"><span
                                      style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                      style="letter-spacing:0.03em">{{listing[4]['subheading']}}</span></span>
                                    </p>

                                      <p class="font_8" style="line-height:1.7em;font-size:16px"><span
                                        style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                                        style="letter-spacing:0.03em"><span
                                        class="wixGuard">&#8203;</span></span></span>
                                      </p>

                                      <ul class="font_8"
                                          style="font-family:avenir-lt-w01_35-light1475496,sans-serif;letter-spacing:0.03em;font-size:16px">
                                        <li style="line-height:2em" *ngFor="let  point of listing[4]['points']">
                                          <p class="font_8" style="line-height:2em;font-size:16px"><span
                                            style="font-family:avenir-lt-w01_35-light1475496,sans-serif">{{point}}</span>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer></footer>
        </div>
      </div>
    </div>
  </div>
</ng-template>
