import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {trigger, transition, query, style, animate, group} from '@angular/animations';
import {DOCUMENT} from "@angular/common";

const left = [
  query(':enter, :leave', style({}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(-100%)'}), animate('5s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('5s ease-out', style({transform: 'translateX(100%)'}))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(100%)'}), animate('5s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('5s ease-out', style({transform: 'translateX(-100%)'}))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: 'app-home-mobile',
  templateUrl: './home-mobile.component.html',
  styleUrls: ['./home-mobile.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
})
export class HomeMobileComponent implements OnInit, OnDestroy {
  showMobileNavbar = false;
  counter = 0;
  showBackToTopButton = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.document.body.classList.add('device-mobile-optimized');
    this.document.body.classList.add('device-android');
    window.addEventListener('scroll', this.scroll, true);
  }

  toggleNavbar(): void {
    this.counter ? this.counter++ : this.counter--;
    setTimeout(() => {
      this.showMobileNavbar = !this.showMobileNavbar;
    }, 100);
    console.log(this.counter);
  }

  timeout;
  scroll = (event): void => {
    this.showBackToTopButton = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.showBackToTopButton = false;
    }, 3000);
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }
}
