import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";

@Injectable()
export class MobileGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(): boolean {

    if (window.innerWidth >= 768) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}

@Injectable()
export class DesktopGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(): boolean {

    if (window.innerWidth < 768) {
      this.router.navigate(['/home-mobile']);
      return false;
    }

    return true;
  }
}
