import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
  isMobile = false;
  showMobileNavbar = false;
  counter = 0;
  showBackToTopButton = false;
  listing = [
    {
      heading: 'Low and Mid-Rise Condos',
      subheading: 'Maintenance, rent collection, tenant services, and screenings - we  are capable of navigating the high-level and detail-specific needs of your property.',
      points: [
        'Sales and leasing processes',
        'Tenant screening',
        'Rent collection',
        'Maintenance and repairs',
        'Financials, quarterly and annual budgets',
        'Vendor negotiation and contract review',
        'Inspections and project management']
    },
    {
      heading: 'High-Rise Condos',
      subheading: 'Our Team is well-equipped to handle every need of your building’s residents. We are skilled at navigating and meeting the demands of luxury communities across Florida.',
      points: [
        'Customized programs and services for luxury living spaces',
        'Financial reporting, budgets, and audits',
        'Comprehensive property inspections',
        'Coordination of maintenance and repairs',
        'Vendor screening and coordination',
        'Customized maintenance programs and landscaping']
    },
    {
      heading: 'HOA Associations',
      subheading: 'Kelsey Management provides a complete scope of services to ensure seamless financial, administrative, and operational requirements for your community.',
      points: [
        'Unique programs for the needs of your community',
        'Small or master-planned, townhouses or single-family homes',
        'Property inspections',
        'Financials, quarterly and annual budgets',
        'Security management and maintenance',
        'Buyer and tenant communications and relations',
        'Vendor and utility contracts']
    },
    {
      heading: 'Commercial Properties',
      subheading: 'With a team committed to comprehensive management, we successfully operate and oversee commercial properties of all sizes to create successful outcomes for your business.',
      points: [
        'Financial reporting and management',
        'Financial analysis and budgetary needs',
        'Ensure code compliance',
        'Tenant oversight',
        'Contract negotiation',
        'Maintenance and repairs',
        'Vendor sourcing and utility coordination']
    },
    {
      heading: 'Developer Services',
      subheading: 'With our expertise in full service support and management, we have a strong track record of partnering with developers to make sure their business goals become a reality.',
      points: [
        'Budget development and updates',
        'Financial reporting and analysis',
        'On-going project management',
        'Dedication to ensuring developer goals are met',
        'Assist in creating enhance property value initiatives',
        'Management programs per closed units',
        'Sales process oversight and assistance',
        'Resident screening and onboarding']
    }
  ]

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.isMobile = window.innerWidth < 768;
    if (this.isMobile) {
      this.document.body.classList.add('device-mobile-optimized');
      this.document.body.classList.add('device-android');
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }

  toggleNavbar(): void {
    this.counter ? this.counter++ : this.counter--;
    this.showMobileNavbar = !this.showMobileNavbar;
    console.log(this.counter);
  }

  timeout;
  scroll = (event): void => {
    this.showBackToTopButton = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.showBackToTopButton = false;
    }, 3000);
    debugger
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }
}
