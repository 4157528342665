import { Component, OnInit } from '@angular/core';
import {textZoomIn} from "../animations/textZoomIn";

@Component({
  selector: 'app-home-desktop',
  templateUrl: './home-desktop.component.html',
  styleUrls: ['./home-desktop.component.scss'],
  animations: textZoomIn
})
export class HomeDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
