import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {OurServicesComponent} from "./our-services/our-services.component";
import {HomeDesktopComponent} from "./home-desktop/home-desktop.component";
import {HomeMobileComponent} from "./home-mobile/home-mobile.component";
import {DesktopGuard, MobileGuard} from "./breakpoint-guard";

const routes: Routes = [{
  component: OurServicesComponent,
  path: 'our-services'
},
  {
    path: '',
    component: HomeDesktopComponent,
    canActivate: [DesktopGuard]
  },
  {
    path: 'index-copy1.html',
    component: HomeDesktopComponent,
    canActivate: [DesktopGuard]
  },
  {path: '**', redirectTo: ''},
  {
    path: 'home-mobile',
    component: HomeMobileComponent,
    canActivate: [MobileGuard],
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
