import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isMobile = false;
  lat = 51.678418;
  lng = 7.809007;
  constructor() {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
  }

}
