<ng-container *ngIf="isMobile;else desktop">

  <div id="SITE_FOOTER" style="padding: 0px 10px 0px 10px;" class="_11CDm" tabindex="-1">
    <div class="_1nb6T">
      <div class="mTQQp"></div>
    </div>
    <div class="_2WCQx">

      <div class="_3l5s9">
        <div class="_1VpWy"></div>
      </div>

      <div class="_3sXYI">
        <div class="">
          <div>
            <div id="comp-j7gi7kz8" style="padding:20px 0px 30px 0px" class="_1Z_nJ animating-screenIn-exit">
              <h2 animateOnScroll animationName="animate__animated animate__slideInUp animated" class="hide-on-init font_4"
                  style="letter-spacing:0.05em;text-align:center;font-size:23px;color:#FFFFFF;font-family:avenir-lt-w01_85-heavy1475544,sans-serif">
                Contact Us
              </h2>
            </div>
            <!--<div id="comp-kjhtzm24"  class="_1Z_nJ">-->
              <!--<h3 class="font_5"-->
                  <!--style="color:#FFFFFF;font-size:23px;font-family:avenir-lt-w01_85-heavy1475544,sans-serif">-->
                <!--Address-->
              <!--</h3>-->
            <!--</div>-->
            <!--<div id="comp-kjhsvop5" class="_1Z_nJ">-->
              <!--<p class="font_8"-->
                 <!--style="letter-spacing:0.04em;color:#FFFFFF;line-height:1.7em;font-size:14px;font-family:avenir-lt-w01_35-light1475496,sans-serif">-->
                <!--11415 Whistlers Cove Blvd. Suite 1112-->
              <!--</p>-->

              <!--<p class="font_8"-->
                 <!--style="letter-spacing:0.04em;color:#FFFFFF;line-height:1.7em;font-size:14px;font-family:avenir-lt-w01_35-light1475496,sans-serif">-->
                <!--Naples, FL 34113-->
              <!--</p>-->
            <!--</div>-->
            <!--<div id="comp-kjhsq2fw" tabindex="0" class="_1LodC">-->
              <!--<div title="Google Maps" aria-label="Google Maps" data-src="">-->
              <!--<div id="mapContainer_comp-kjhsq2fw" class="_3HA2p">-->

                <!--<agm-map [latitude]="lat" [longitude]="lng">-->
                  <!--<agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>-->
                <!--</agm-map>-->

              <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <div id="comp-kjhsxn1p" class="_1Z_nJ" data-testid="richTextElement">
              <h3 class="font_5"
                  style="font-size:23px;color:#FFFFFF;font-family:avenir-lt-w01_85-heavy1475544,sans-serif">
                To Speak With Our Team
              </h3>
            </div>
            <div id="comp-kjhsy0ul" class="_1Z_nJ" data-testid="richTextElement">
              <p class="font_8" style="line-height:1.7em;font-size:14px">
                <a href="mailto:mgmt@kelseymanagement.com" target="_self" tabindex="0">
                <span
                  style="color:#FFFFFF;text-decoration:underline;font-family:avenir-lt-w01_35-light1475496,sans-serif">
                  mgmt@kelseymanagement.com</span>
                </a>
              </p>

              <p class="font_8" style="line-height:1.7em;font-size:14px">
                <a href="tel:+1 954-540-9904"
                   tabindex="0">
                    <span
                      style="text-decoration:underline;font-family:avenir-lt-w01_35-light1475496,sans-serif;color:#FFFFFF;letter-spacing:0.04em">
                      561-989-8948
                  </span>
                </a>
              </p>

              <p class="font_8" style="line-height:0.8em;font-size:14px">
                  <span style="letter-spacing:0.04em;font-family:avenir-lt-w01_35-light1475496,sans-serif;color:#FFFFFF"
                        class="wixGuard">
                    &#8203;
                  </span>
              </p>

              <!--<p class="font_8" style="line-height:1.7em;font-size:14px">-->
                <!--<a href="mailto:Marelys@tg-pmg.com" target="_self" tabindex="0">-->
                    <!--<span-->
                      <!--style="text-decoration:underline;font-family:avenir-lt-w01_35-light1475496,sans-serif;color:#FFFFFF;letter-spacing:0.04em">-->
                    <!--Marelys@tg-pmg.com-->
                    <!--</span>-->
                <!--</a>-->
              <!--</p>-->

              <!--<p class="font_8" style="line-height:1.7em;font-size:14px">-->
                <!--<a href="tel:+1 239-682-1694" tabindex="0">-->
                    <!--<span-->
                      <!--style="font-family:avenir-lt-w01_35-light1475496,sans-serif;color:#FFFFFF;letter-spacing:0.04em">-->
                      <!--239-682-1694-->
                    <!--</span>-->
                <!--</a>-->
              <!--</p>-->
            </div>
            <div id="comp-kjhtex94" class="_3e89g">
              <div data-mesh-id="comp-kjhtex94inlineContent" data-testid="inline-content" class="">
                <div data-mesh-id="comp-kjhtex94inlineContent-gridContainer" data-testid="mesh-container-content">
                  <form id="comp-kjhtex9g" class="Cdk9j">
                    <div data-mesh-id="comp-kjhtex9ginlineContent" data-testid="inline-content" class="">
                      <div data-mesh-id="comp-kjhtex9ginlineContent-gridContainer"
                           data-testid="mesh-container-content">
                        <div id="comp-kjhtex9q" class="_1Z_nJ" data-testid="richTextElement"><h5 class="font_5"
                                                                                                 style="font-size:19px">
                            <span style="font-size:19px"><span style="color:#FFFFFF"><span
                              style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Alternatively You Can Fill In The Following Contact Form</span></span></span>
                        </h5></div>
                        <div id="comp-kjhtex9u1" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="text" name="name" id="input_comp-kjhtex9u1"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Name"
                                                    required="" maxlength="100" tabindex="0"></div>
                        </div>
                        <div id="comp-kjhtexa0" class="_31qkb">
                          <div class="Lc5OM"><input type="text" name="last-name" id="input_comp-kjhtexa0"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Last Name"
                                                    maxlength="100" tabindex="0"></div>
                        </div>
                        <div id="comp-kjhtexa4" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="email" name="email" id="input_comp-kjhtexa4"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Email"
                                                    required="" pattern="^.+@.+\.[a-zA-Z]{2,63}$" maxlength="250"
                                                    tabindex="0"></div>
                        </div>
                        <div id="comp-kjhtexa8" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="tel" name="phone-number" id="input_comp-kjhtexa8"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Phone Number"
                                                    required="" maxlength="50" tabindex="0"></div>
                        </div>
                        <div id="comp-kjhtexab3" class="_42POZ"><label for="textarea_comp-kjhtexab3"
                                                                       class="_2SAi8"></label><textarea
                          id="textarea_comp-kjhtexab3" class="_1qIig has-custom-focus"
                          placeholder="Type your message here..." tabindex="0"></textarea></div>
                        <div id="comp-kjhtexag2" aria-disabled="false" class="_22oPj">
                          <button data-testid="buttonElement" class="_1_kc0 getfreeevaluation" tabindex="0"><span
                            class="_3fUtn">Submit</span></button>
                        </div>
                      </div>
                    </div>
                    <div class="FHREF"><label>Please leave this field empty.<input type="text" name="message"
                                                                                   autocomplete="nada" tabindex="-1"
                                                                                   value=""></label></div>
                  </form>
                </div>
              </div>
            </div>
            <div animateOnScroll animationName="animate__animated animate__slideInUp animated" id="comp-j6qjegmy" class="hide-on-init _1Z_nJ animating-screenIn-exit">
              <p class="font_9 color_11"
                 style="line-height:1.6em;text-align:center;font-size:11px;letter-spacing:0.1em">
                © 2023 by Kelsey Management.
              </p>

              <p class="font_9 color_11"
                 style="line-height:1.6em;text-align:center;font-size:11px;letter-spacing:0.1em">
                All rights reserved
              </p>

              <p class="font_9 color_11"
                 style="line-height:1.6em;text-align:center;font-size:12px;letter-spacing:0.1em">
                CAB 5785
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
<ng-template #desktop>
  <div id="SITE_FOOTER" class="_11CDm" tabindex="-1">
    <div class="_1nb6T">
      <div class="mTQQp"></div>
    </div>
    <div class="_2WCQx">
      <div class="_3l5s9">
        <div class="_1VpWy"></div>
      </div>
      <div class="_3sXYI">
        <div data-mesh-id="SITE_FOOTERinlineContent" data-testid="inline-content" class="">
          <div data-mesh-id="SITE_FOOTERinlineContent-gridContainer" data-testid="mesh-container-content">
            <div id="comp-j7gi7kz8" animateOnScroll animationName="animate__animated animate__fadeIn animated"
                 class="hide-on-init _1Z_nJ animating-screenIn-exit" data-testid="richTextElement">
              <h2
                class="font_4" style="text-align: center; font-size: 45px;"><span style="font-size:45px;"><span
                style="color:#FFFFFF"><span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span
                style="letter-spacing:0.05em">Contact Us</span></span></span></span></h2></div>
            <div id="comp-kjhtzm24" style="width: 310px!important;" animateOnScroll animationName="animate__animated animate__fadeIn animated"
                 class="hide-on-init _1Z_nJ animating-screenIn-exit" data-testid="richTextElement">
              <h3 class="font_5" style="font-size: 26px;">
                <span style="font-size:26px;">
                  <span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">
                    <span style="color:#FFFFFF">
                      <!--Address-->
                      To Speak With Our Team
                    </span>
                  </span>
                </span>
              </h3>
            </div>
            <div id="comp-kjhsvop5" animateOnScroll animationName="animate__animated animate__fadeIn animated"
                 class="hide-on-init _1Z_nJ animating-screenIn-exit" data-testid="richTextElement">
              <p class="font_8" style="line-height:1.7em;font-size:16px">
                      <span style="color:#FFFFFF">
                        <span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">
                          <span style="letter-spacing:0.04em">
                            <!--11415 Whistlers Cove Blvd. Suite 1112-->
                            <a href="mailto:mgmt@kelseymanagement.com" style="text-decoration:underline;" target="_self">
                              mgmt@kelseymanagement.com
                            </a>
                          </span>
                        </span>
                      </span>
              </p>

              <p class="font_8" style="line-height:1.7em;font-size:16px"><span style="color:#FFFFFF"><span
                style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span
                style="letter-spacing:0.04em; text-decoration:underline;">
                <!--Naples, FL 34113-->
                561-989-8948
              </span>
              </span>
              </span>
              </p>

            </div>
            <!--<div id="comp-kjhsq2fw" tabindex="0" class="_1LodC">-->
              <!--<div title="Google Maps" aria-label="Google Maps" data-src="">-->
              <!--<div id="mapContainer_comp-kjhsq2fw" class="_3HA2p">-->
                <!--<agm-map [latitude]="lat" [longitude]="lng">-->
                  <!--<agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>-->
                <!--</agm-map>-->
              <!--</div>-->
              <!--</div>-->
            <!--</div>-->
            <!--<div id="comp-kjhsxn1p" animateOnScroll animationName="animate__animated animate__fadeIn animated"-->
                 <!--class="hide-on-init _1Z_nJ"-->
            <!--&gt;<h3-->
              <!--class="font_5" style="font-size: 26px;"><span style="font-size:26px;"><span style="color:#FFFFFF"><span-->
              <!--style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">To Speak With Our Team</span></span></span>-->
            <!--</h3></div>-->
            <!--<div id="comp-kjhsy0ul" animateOnScroll animationName="animate__animated animate__fadeIn animated"-->
                 <!--class="hide-on-init _1Z_nJ"-->
            <!--&gt;<p class="font_8"-->
                <!--style="line-height:1.7em;font-size:16px">-->
              <!--<a href="mailto:mgmt@kelseymanagement.com" target="_self"><span style="color:#FFFFFF"><span-->
                <!--style="text-decoration:underline;"><span-->
                <!--style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span-->
                <!--style="letter-spacing:0.04em">mgmt@kelseymanagement.com</span></span></span></span></a></p>-->

              <!--<p class="font_8" style="line-height:1.7em;font-size:16px"><a href="tel:+1 954-540-9904"><span-->
                <!--style="color:#FFFFFF"><span style="text-decoration:underline;"><span-->
                <!--style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span-->
                <!--style="letter-spacing:0.04em"> 561-989-8948</span></span></span></span></a></p>-->

              <!--<p class="font_8" style="line-height:0.8em;font-size:16px"><span style="color:#FFFFFF"><span-->
                <!--style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span-->
                <!--style="letter-spacing:0.04em"><span class="wixGuard">&#8203;</span></span></span></span></p>-->

              <!--<p class="font_8" style="line-height:1.7em;font-size:16px"><a href="mailto:Marelys@tg-pmg.com"-->
                                                                            <!--target="_self"><span-->
                <!--style="color:#FFFFFF"><span style="text-decoration:underline;"><span-->
                <!--style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span-->
                <!--style="letter-spacing:0.04em">Marelys@tg-pmg.com</span></span></span></span></a></p>-->

              <!--<p class="font_8" style="line-height:1.7em;font-size:16px"><a href="tel:+1 239-682-1694"><span-->
                <!--style="color:#FFFFFF"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span-->
                <!--style="letter-spacing:0.04em">239-682-1694</span></span></span></a></p>-->
                <!--</div>-->
            <div id="comp-kjhtex94" class="_3e89g">
              <div data-mesh-id="comp-kjhtex94inlineContent" data-testid="inline-content" class="">
                <div data-mesh-id="comp-kjhtex94inlineContent-gridContainer"
                     data-testid="mesh-container-content">
                  <form id="comp-kjhtex9g" class="Cdk9j">
                    <div data-mesh-id="comp-kjhtex9ginlineContent" data-testid="inline-content" class="">
                      <div data-mesh-id="comp-kjhtex9ginlineContent-gridContainer"
                           data-testid="mesh-container-content">
                        <div id="comp-kjhtex9q" class="_1Z_nJ" data-testid="richTextElement"><h5 class="font_5"
                                                                                                 style="font-size: 25px;">
                                  <span style="font-size:25px;"><span style="color:#FFFFFF"><span
                                    style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">Alternatively You Can Fill In The Following Contact Form</span></span></span>
                        </h5></div>
                        <div id="comp-kjhtex9u1" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="text" name="name" id="input_comp-kjhtex9u1"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Name"
                                                    required="" maxlength="100"></div>
                        </div>
                        <div id="comp-kjhtexa0" class="_31qkb">
                          <div class="Lc5OM"><input type="text" name="last-name" id="input_comp-kjhtexa0"
                                                    class="A1_tY has-custom-focus" value=""
                                                    placeholder="Last Name" maxlength="100"></div>
                        </div>
                        <div id="comp-kjhtexa4" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="email" name="email" id="input_comp-kjhtexa4"
                                                    class="A1_tY has-custom-focus" value="" placeholder="Email"
                                                    required="" pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                                    maxlength="250"></div>
                        </div>
                        <div id="comp-kjhtexa8" class="_31qkb PEjbJ">
                          <div class="Lc5OM"><input type="tel" name="phone-number" id="input_comp-kjhtexa8"
                                                    class="A1_tY has-custom-focus" value=""
                                                    placeholder="Phone Number" required="" maxlength="50"></div>
                        </div>
                        <div id="comp-kjhtexab3" class="_42POZ"><label for="textarea_comp-kjhtexab3"
                                                                       class="_2SAi8"></label><textarea
                          id="textarea_comp-kjhtexab3" class="_1qIig has-custom-focus"
                          placeholder="Type your message here..."></textarea></div>
                        <div id="comp-kjhtexag2" aria-disabled="false" class="_22oPj">
                          <button data-testid="buttonElement" class="_1_kc0 getfreeevaluation"><span class="_3fUtn">Submit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="FHREF"><label>Please leave this field empty.<input type="text" name="message"
                                                                                   autocomplete="nada"
                                                                                   tabindex="-1"
                                                                                   value=""></label></div>
                  </form>
                </div>
              </div>
            </div>
            <div id="comp-j6qjegmy" class="_1Z_nJ" data-testid="richTextElement"><p class="font_9"
                                                                                    style="line-height:1.6em;text-align:center;font-size:13px">
                      <span class="color_11"><span
                        style="letter-spacing:0.1em">© 2023 by Kelsey Management. </span></span></p>

              <p class="font_9" style="line-height:1.6em;text-align:center;font-size:13px"><span
                class="color_11"><span style="letter-spacing:0.1em">All rights reserved</span></span></p>

              <p class="font_9" style="line-height: 1.6em; text-align: center; font-size: 16px;"><span
                style="font-size:16px;"><span class="color_11"><span
                style="letter-spacing:0.1em">CAB 5785</span></span></span></p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
